<template>
    <div id="times">
        <div class="mb-2">{{ createDateTextWithWeekday(appStore.date) }}</div>

        <template v-for="clinicId of Object.keys(slots)">
            <div class="rounded-2xl border border-[#efedf2] mb-4 p-4 flex flex-col gap-4 items-start relative w-full bg-white">
                <div class="flex justify-between items-end self-stretch relative w-full bg-transparent">
                    <div class="flex gap-1 items-center relative bg-transparent">
                        <AppUiIconMarker/>
                        <p class="text-center font-medium leading-5 text-base text-[#292b33]">
                            {{ clinicStore.getClinicById(clinicId).attributes.title }}
                        </p>
                    </div>
                    <template v-if="appStore.type === 'doc'">
                        <div class="rounded-[28px] px-2 py-1.5 flex gap-2 justify-center items-center relative bg-[#f0fcfb]"
                             v-if="appStore.hotDocPrice(appStore.docId, clinicId, appStore.hotListPrices)">
                            <p class="tracking-[-0.02em] font-medium leading-3 text-[10px] text-[#292b33] whitespace-nowrap">
                                {{
                                numberWithSpaces(appStore.hotDocPrice(appStore.docId, clinicId, appStore.hotListPrices))
                                }} ₽
                            </p>
                        </div>
                    </template>
                </div>
                <div v-for="(docMisId, idx) of Object.keys(slots[clinicId])" class="w-full" :key="`list${docMisId}`">
                    <template v-if="appStore.type !== 'doc'">
                        <AppCardDocOutline :docId="docStore.getDocByMisId(docMisId).id" :clinicId="clinicId"
                                           :hotListPrices="appStore.hotListPrices"/>
                    </template>

                    <template v-if="!onlyMess(docMisId)">
                        <time class="flex gap-2 items-start self-stretch w-full bg-transparent flex-wrap">
                            <div v-for="slot of slots[clinicId][docMisId]"
                                 :key="`${clinicId}${docMisId}${slot.name}`"
                                 @click="appStore.time = slot.name; appStore.clinicId = clinicId; appStore.priceId = slot.priceId; appStore.docMisId = docMisId; navStore.next()"
                                 class="rounded-3xl cursor-pointer border p-4 flex justify-between items-center relative w-[67px] "
                                 :class="{'bg-white border-[#efedf2]': slot.name !== appStore.time, 'bg-primary-40 border-primary-50': slot.name === appStore.time && appStore.docMisId === docMisId}"
                            >
                                <p class="font-medium leading-5 text-sm text-[#292b33]">{{ slot.name }}</p>
                            </div>
                        </time>
                    </template>
                    <template v-else>
                        <UISocialButtons :link="link(docMisId)"/>
                    </template>

                    <div class="w-full mt-5 mb-2 h-px bg-[#efedf2]"
                         v-if="idx !== (Object.keys(slots[clinicId]).length -1)"></div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {useSchedule} from "~/store/app/schedule";
import {useApp} from "~/store/app/app";
import {createDateTextWithWeekday, textDate} from "~/libs/date";
import {useClinics} from "~/store/clinics";
import {useDocs} from "~/store/docs";
import {numberWithSpaces} from "../../../../libs/ui";
import {useNav} from "~/store/app/nav";

export default {
    setup() {
        const appStore = useApp();
        const scheduleStore = useSchedule();
        const clinicStore = useClinics();
        const docStore = useDocs();
        const navStore = useNav();

        return {
            appStore,
            scheduleStore,
            clinicStore,
            docStore,
            navStore
        }
    },
    computed: {
        slots() {
            return this.scheduleStore.getSlotsByDate(textDate(this.appStore.date))[textDate(this.appStore.date)];
        },
    },
    methods: {
        numberWithSpaces, textDate, createDateTextWithWeekday,
        onlyMess(docMisId) {
            return this.docStore.getDocByMisId(docMisId).attributes.onlyMess;
        },
        link(docMisId) {
            return this.docStore.getDocByMisId(docMisId).attributes.messLink;
        }
    }
}
</script>