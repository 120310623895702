<template>
    <div>
        <div class="overflow-hidden rounded-2xl border-2 border-[#f8f7fa] px-6 py-8 flex flex-col gap-10 items-center relative w-[328px] bg-transparent"
             style="margin: 0 auto">
            <div class="flex flex-col gap-4 items-center relative bg-transparent">
                <div class="overflow-hidden relative w-[120px] h-[120px] bg-transparent">
                    <img src="/complite.png" alt="">
                </div>
                <p class="tracking-[0.14em] uppercase text-center leading-6 text-sm text-black">
                    Визит отменен
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {useApp} from "~/store/app/app";
import {createDateText} from "~/libs/date";
import {usePrices} from "~/store/price";
import {numberWithSpaces} from "~/libs/ui";

export default {
    methods: {numberWithSpaces},
    setup() {
        const appStore = useApp();
        const hotDoc = appStore.hotDoc;
        return {
            appStore,
            hotDoc,
            dateTime: `${createDateText(appStore.date)}, ${appStore.time}`,
        };
    }
}
</script>