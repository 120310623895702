<template>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5"
              d="M44 24C44 35.046 35.046 44 24 44C12.954 44 4 35.046 4 24C4 12.954 12.954 4 24 4C35.046 4 44 12.954 44 24Z"
              fill="#72E0D8"></path>
        <path d="M33.614 38.022C30.7856 39.9669 27.4325 41.0055 24 41C20.5674 41.0055 17.2144 39.9669 14.386 38.022C13.178 37.192 12.662 35.612 13.366 34.326C14.82 31.66 17.82 30 24 30C30.18 30 33.18 31.66 34.636 34.326C35.336 35.612 34.822 37.192 33.614 38.022ZM24 24C25.5913 24 27.1174 23.3679 28.2426 22.2426C29.3678 21.1174 30 19.5913 30 18C30 16.4087 29.3678 14.8826 28.2426 13.7574C27.1174 12.6321 25.5913 12 24 12C22.4087 12 20.8825 12.6321 19.7573 13.7574C18.6321 14.8826 18 16.4087 18 18C18 19.5913 18.6321 21.1174 19.7573 22.2426C20.8825 23.3679 22.4087 24 24 24Z"
              fill="#72E0D8"></path>
    </svg>
</template>
<script setup lang="ts">
</script>