<template>
    <div class="overflow-auto w-full h-full p-4 pt-0" id="wrap_top">
        <AppOrderForm v-if="orderStore.status === null"/>
        <AppOrderSuccess v-else/>
    </div>
</template>

<script>
import {useOrder} from "~/store/app/order";

export default {
    setup() {
        const orderStore = useOrder();

        return {
            orderStore
        }
    },
}
</script>