<template>
    <div class="rounded-2xl cursor-pointer  pl-4 pr-6 py-4 flex gap-4 items-center relative w-full border-2"
         :class="{
        'md:hover:border-[#bbefec] md:hover:bg-[#f0fcfb] bg-white': !active,
        'border-[#bbefec] md:hover:border-[#bbefec] md:hover:bg-[#f0fcfb] bg-[#f0fcfb]': active
    }">
        <div class="flex gap-4 items-start flex-1 relative w-full bg-transparent">
            <div class="relative w-12 h-12 bg-transparent">
                <img v-if="doc.photoMiniSrc" :src="doc.photoMiniSrc"
                     class="rounded-[40px] border-2 border-white w-12 h-12 bg-primary-10"/>
                <img v-else :src="docsStore.getPhotoPlaceholder(doc)"
                     class="mx-auto min-w-12 w-full h-full rounded-full object-cover object-top"/>
            </div>
            <div class="flex flex-col gap-0 items-start flex-1 relative w-full bg-transparent">
                <div class="flex gap-0 items-center justify-between self-stretch relative w-full bg-transparent">
                    <p class="font-medium leading-5 text-sm text-[#292b33]">{{ doc.name }}</p>
                    <div class="rounded-[28px] px-2 py-1.5 flex gap-2 justify-center items-center relative bg-[#f0fcfb]" v-if="appStore.hotDocPrice(doc.id)">
                        <p class="tracking-[-0.02em] font-medium leading-3 text-[10px] text-[#292b33]">
                            {{ numberWithSpaces(appStore.hotDocPrice(doc.id))}} ₽
                        </p>
                    </div>
                </div>
                <p class="font-light leading-4 text-xs text-[#9e9ba3]">{{ doc.special }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import {useDocs} from "~/store/docs";
import {useApp} from "~/store/app/app";
import {numberWithSpaces, generateTextAge} from "~/libs/ui";

export default {
    props: ["docId", "active", "style"],
    data() {
        return {
            isActive: this.active,
        }
    },
    setup(props) {
        const appStore = useApp();
        const docsStore = useDocs();
        const doc = docsStore.getDocById(props.docId);
        return {
            appStore,
            docsStore,
            doc,
            numberWithSpaces
        }
    },
}
</script>
