<template>
    <div class="quiz">
        <div class="mb-8">
            <AppUiHeader title="Приём будет в клинике?"/>
            <AppCardBtn title="Да, в клинике" :active="appStore.where === 'clinic'" @click="setWhere('clinic')"
                        :disabled="checkDisabled('clinic')"/>
            <AppCardBtn title="Онлайн" :active="appStore.where === 'online'" @click="setWhere('online')"
                        :disabled="checkDisabled('online')"/>
        </div>

        <div class="mb-8" v-show="showGroup()">
            <AppUiHeader title="Какая услуга нужна?"/>
            <AppCardBtn
                    v-for="group of appStore.hotListGroups" :key="group.id"
                    :title="group.attributes.title"
                    :active="appStore.groupId === group.id"
                    @click="selectGroup(group.id)"
            />
        </div>

        <div class="mb-8" v-show="showPediatrist()" id="reason">
            <AppUiHeader title="Причина обращения"/>
            <AppCardBtn :title="reason.title" :subtitle="reason.subtitle" :active="reason.select"
                        @click="appStore.setReason(idx); navStore.next();"
                        :key="`reason-${idx}`"
                        v-for="(reason, idx) of appStore.listReasons"/>
        </div>
    </div>
</template>
<script>
import {useApp} from "~/store/app/app";
import {useNav} from "~/store/app/nav";
import {scrollTo} from "~/libs/ui";
import {usePrices} from "~/store/price";

export default {
    setup() {
        const appStore = useApp();
        const navStore = useNav();

        return {
            appStore,
            navStore
        }
    },
    methods: {
        checkDisabled(where) {
            switch (this.appStore.type) {
                case 'doc':
                    if (where === 'clinic')
                        return !this.appStore.hotDoc.attributes.clinic;
                    if (where === 'online')
                        return !this.appStore.hotDoc.attributes.online;
                    break;
                case 'service':
                    const priceStore = usePrices();
                    const service = priceStore.getPriceById(this.appStore.serviceId);
                    if (where === 'clinic')
                        return !service.attributes.clinic;
                    if (where === 'online')
                        return !service.attributes.online;
                    return false;
                case 'special':
                    return false;
            }
        },
        setWhere(where) {
            if (this.checkDisabled(where))
                return;

            this.appStore.where = where;

            if (this.appStore.type === 'service') {
                this.navStore.next();
            }

            if (this.appStore.type === 'special' || this.appStore.type === 'doc') {
                if (this.appStore.hotListGroups.length === 0) {
                    if (this.appStore.debug)
                        console.error('Список групп пустой');
                    this.navStore.error = true;
                }
                if (this.appStore.hotListGroups.length === 1) {
                    this.appStore.groupId = this.appStore.hotListGroups[0].id;
                    if (!(this.appStore.isPediatrist && this.appStore.groupId === 1))
                        this.navStore.next();
                }
            }
        },
        showGroup() {
            if (this.appStore.where && this.appStore.hotListGroups.length) {
                switch (this.appStore.type) {
                    case 'service':
                        return false;
                    case 'special':
                        return !(this.appStore.isPediatrist && this.appStore.hotListGroups.length === 1 && this.appStore.groupId === 1);
                    case 'doc':
                        return !(this.appStore.isPediatrist && this.appStore.hotListGroups.length === 1 && this.appStore.groupId === 1);
                }
            }
        },
        showPediatrist() {
            let result;

            switch (this.appStore.type) {
                case 'service':
                    result = false;
                    break;
                case 'special':
                    result = this.appStore.where && this.appStore.isPediatrist;
                    break;
                case 'doc':
                    result = this.appStore.where && this.appStore.isPediatrist && this.appStore.groupId === 1;
                    break;
            }

            if (result) {
                scrollTo('reason', 'quiz');
            }
            return result;
        },
        selectGroup(id) {
            this.appStore.groupId = id;
            if (!this.appStore.isPediatrist || (this.appStore.isPediatrist && this.appStore.groupId !== 1))
                this.navStore.next();
        }
    },
    mounted() {
        this.appStore.clearApp(4);
    }
}
</script>