<template>
    <div class="flex flex-col gap-6 items-start relative w-full bg-transparent">
        <div class="flex gap-2 items-start self-stretch relative w-full bg-transparent">
            <div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="20" height="20" rx="6" fill="#292B33"></rect>
                    <path d="M5.8335 9.99996L8.41486 12.5813C8.52333 12.6898 8.69921 12.6898 8.80769 12.5813L14.7224 6.66663"
                          stroke="white" stroke-width="1.5" stroke-linecap="round"></path>
                </svg>
            </div>
            <p class="font-light leading-4 text-xs text-[#2d2a34]">Я&nbsp;даю&nbsp;согласие на&nbsp;обработку
                персональных данных, а также ознакомился с правилами проведения онлайн-консультации.</p></div>
        <AppUiBtn text="Записаться" type="allow" @click="navStore.next()" v-if="patientStore.checkValid.length === 0"/>
        <AppUiBtn text="Заполните все поля" class="opacity-25 cursor-not-allowed" type="disallow" v-else @click="patientStore.error = true;"></AppUiBtn>
    </div>
</template>
<script>
import {useNav} from "~/store/app/nav";
import {usePatient} from "~/store/app/patient";

export default {
    setup() {
        const navStore = useNav();
        const patientStore = usePatient();
        return {
            navStore,
            patientStore
        }
    }
}
</script>