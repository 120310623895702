<template>
    <div class="mb-3 relative">
        <Listbox v-model="selectedPerson">
            <div class="relative mt-1 z-10">
                <ListboxButton
                        class="w-full overflow-hidden rounded-2xl pl-4 pr-2 py-4 flex gap-0 items-center relative bg-[#f8f7fa]">
                    <span class="block truncate" :class="[selectedPerson?.title ? 'text-black' : 'text-gray-40']">
                        {{ selectedPerson?.title ? selectedPerson?.title : 'Выберите возраст' }}
                    </span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
                    </span>
                </ListboxButton>

                <transition
                        leave-active-class="transition duration-100 ease-in"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0">
                    <ListboxOptions
                            class="absolute mt-1 max-h-[50vh] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                        <ListboxOption
                                v-slot="{ active, selected }"
                                v-for="age in listAgeFilter"
                                :key="age.name"
                                :value="age"
                                as="template">
                            <li :class="[
                                active ? 'bg-primary-40 text-black overflow-hidden rounded-2xl p-4 flex gap-2 items-center self-stretch relative w-full bg-[#bbefec]' : 'text-gray-900',
                                'relative cursor-pointer select-none py-2 px-5 pr-4',]">
                              <span :class="[
                                selected ? 'font-medium' : 'font-normal',
                                'block truncate font-light leading-6 text-base text-[#292b33]',]">
                                  {{ age.title }}
                              </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>

<script>
import {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
} from '@headlessui/vue'
import {CheckIcon, SelectorIcon} from "@heroicons/vue/solid";
import {usePatient} from "~/store/app/patient";

export default {
    props: ["ageFrom", "ageTo"],
    data() {
        const appPatient = usePatient();

        let listAge = [
            {title: 'до 1 года', age: 0},
            {title: '1 год', age: 1},
            {title: '2 года', age: 2},
            {title: '3 года', age: 3},
            {title: '4 года', age: 4},
            {title: '5 лет', age: 5},
        ]

        for (let i = 6; i < 18; i++) {
            listAge.push({
                title: `${i} лет`,
                age: i,
            })
        }
        listAge.push({
            title: `18 лет и старше`,
            age: 18,
        })


        let selectedPerson = null;
        if (appPatient.patient.age !== null) {
            selectedPerson = listAge.filter(item => item.age === appPatient.patient.age)[0]
        }

        return {
            appPatient,
            listAge,
            selectedPerson
        }
    },
    setup() {
        return {}
    },
    computed: {
        listAgeFilter() {
            return this.listAge.filter(item => item.age >= this.ageFrom && item.age <= this.ageTo)
        }
    },
    components: {
        Listbox,
        ListboxLabel,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
        CheckIcon, SelectorIcon
    },
    watch: {
        selectedPerson(val) {
            this.appPatient.patient.age = val.age;
        }
    },
}
</script>
