<template>
    <div>
        <AppStep3Doc v-if="appStore.type === 'doc'"/>
        <AppStep3Special v-if="appStore.type === 'special'"/>
        <AppStep3Service v-if="appStore.type === 'service'"/>
    </div>
</template>

<script>
import {useApp} from "~/store/app/app";
export default {
    setup() {
        const appStore = useApp();

        return {
            appStore,
        }
    },
}
</script>