<template>
    <div
            class="fixed right-6 sm:right-8 bottom-8 z-10 helper-widget"
            :class="{ 'helper-widget_visible': scrolled }"
    >
        <div
                v-if="opened"
                class="absolute bottom-full right-0 z-10 justify-end flex-col items-end helper-list"
                :class="{ 'list-active': opened }"
        >
            <div
                    @click="openJivo()"
                    v-if="jivoStatus !== 'offline'"
                    class="bg-white shadow-lg rounded-full whitespace-nowrap mb-4 py-2 px-4 flex items-center cursor-pointer"
            >
                <span>Написать в чат</span>
                <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="ml-2.5"
                >
                    <path
                            d="M8.00268 4.5347e-07C6.61475 -0.000466801 5.25058 0.360166 4.0442 1.04646C2.83782 1.73276 1.83074 2.72111 1.12193 3.9144C0.41311 5.10769 0.0269395 6.46486 0.00135916 7.85256C-0.0242212 9.24025 0.311669 10.6107 0.976026 11.8293L0.0293601 15.1493C-0.00395015 15.2659 -0.00483758 15.3894 0.0267935 15.5064C0.0584245 15.6235 0.121376 15.7297 0.208874 15.8136C0.296371 15.8975 0.4051 15.956 0.52336 15.9827C0.64162 16.0095 0.764933 16.0035 0.880026 15.9653L3.99735 14.9267C5.05983 15.5406 6.24931 15.902 7.47377 15.9827C8.69823 16.0634 9.92485 15.8613 11.0587 15.3922C12.1926 14.923 13.2034 14.1993 14.0128 13.277C14.8222 12.3547 15.4086 11.2585 15.7267 10.0733C16.0447 8.88813 16.0858 7.64567 15.8468 6.44205C15.6079 5.23842 15.0952 4.10591 14.3485 3.13211C13.6018 2.15831 12.6411 1.36934 11.5408 0.826223C10.4404 0.283108 9.2298 0.000410599 8.00268 4.5347e-07ZM5.33602 6.66667C5.33602 6.48986 5.40626 6.32029 5.53128 6.19526C5.65631 6.07024 5.82588 6 6.00269 6H10.0027C10.1795 6 10.3491 6.07024 10.4741 6.19526C10.5991 6.32029 10.6693 6.48986 10.6693 6.66667C10.6693 6.84348 10.5991 7.01305 10.4741 7.13807C10.3491 7.2631 10.1795 7.33333 10.0027 7.33333H6.00269C5.82588 7.33333 5.65631 7.2631 5.53128 7.13807C5.40626 7.01305 5.33602 6.84348 5.33602 6.66667ZM6.00269 8.66667H8.66935C8.84616 8.66667 9.01573 8.7369 9.14075 8.86193C9.26578 8.98695 9.33602 9.15652 9.33602 9.33333C9.33602 9.51014 9.26578 9.67971 9.14075 9.80474C9.01573 9.92976 8.84616 10 8.66935 10H6.00269C5.82588 10 5.65631 9.92976 5.53128 9.80474C5.40626 9.67971 5.33602 9.51014 5.33602 9.33333C5.33602 9.15652 5.40626 8.98695 5.53128 8.86193C5.65631 8.7369 5.82588 8.66667 6.00269 8.66667Z"
                            fill="#444444"
                    />
                </svg>
            </div>
            <div
                    @click="slotsStore.openOrder()"
                    class="bg-white shadow-lg rounded-full whitespace-nowrap mb-4 py-2 px-4 flex items-center cursor-pointer"
            >
                <span>Перезвоните мне</span>
                <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="ml-2.5"
                >
                    <path
                            d="M11.7058 12.425L13.1058 11.025C13.2943 10.8388 13.5329 10.7113 13.7925 10.6581C14.0521 10.6048 14.3216 10.6281 14.5683 10.725L16.2745 11.4063C16.5238 11.5074 16.7375 11.6801 16.8888 11.9026C17.04 12.125 17.1221 12.3873 17.1245 12.6563V15.7813C17.1231 15.9643 17.0846 16.1451 17.0114 16.3128C16.9383 16.4805 16.8319 16.6317 16.6988 16.7573C16.5657 16.8828 16.4085 16.9801 16.2368 17.0433C16.065 17.1065 15.8823 17.1343 15.6995 17.125C3.74328 16.3813 1.33078 6.25627 0.874534 2.38128C0.853355 2.19099 0.872706 1.99837 0.931314 1.81609C0.989923 1.63382 1.08646 1.46602 1.21458 1.32374C1.34269 1.18145 1.49948 1.0679 1.67463 0.990565C1.84978 0.913227 2.03932 0.873851 2.23078 0.875026H5.24953C5.51891 0.875823 5.7819 0.957193 6.00466 1.10867C6.22742 1.26015 6.39976 1.4748 6.49953 1.72503L7.18078 3.43128C7.28094 3.67694 7.3065 3.94668 7.25425 4.20678C7.20201 4.46689 7.07428 4.70584 6.88703 4.89378L5.48703 6.29378C5.48703 6.29378 6.29328 11.75 11.7058 12.425Z"
                            fill="#444444"
                    />
                </svg>
            </div>
            <!-- <div
                    class="bg-white shadow-lg rounded-full whitespace-nowrap mb-4 py-2 px-4 flex items-center cursor-pointer"
                  >
                    <span>Пожаловаться</span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-2.5"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.99967 0.833496C4.93717 0.833496 0.833008 4.93766 0.833008 10.0002C0.833008 15.0627 4.93717 19.1668 9.99967 19.1668C15.0622 19.1668 19.1663 15.0627 19.1663 10.0002C19.1663 4.93766 15.0622 0.833496 9.99967 0.833496ZM10.833 5.8335C10.833 5.61248 10.7452 5.40052 10.5889 5.24424C10.4327 5.08796 10.2207 5.00016 9.99967 5.00016C9.77866 5.00016 9.5667 5.08796 9.41042 5.24424C9.25414 5.40052 9.16634 5.61248 9.16634 5.8335V10.8335C9.16634 11.0545 9.25414 11.2665 9.41042 11.4228C9.5667 11.579 9.77866 11.6668 9.99967 11.6668C10.2207 11.6668 10.4327 11.579 10.5889 11.4228C10.7452 11.2665 10.833 11.0545 10.833 10.8335V5.8335ZM10.833 13.7502C10.833 13.5292 10.7452 13.3172 10.5889 13.1609C10.4327 13.0046 10.2207 12.9168 9.99967 12.9168C9.77866 12.9168 9.5667 13.0046 9.41042 13.1609C9.25414 13.3172 9.16634 13.5292 9.16634 13.7502V14.1668C9.16634 14.3878 9.25414 14.5998 9.41042 14.7561C9.5667 14.9124 9.77866 15.0002 9.99967 15.0002C10.2207 15.0002 10.4327 14.9124 10.5889 14.7561C10.7452 14.5998 10.833 14.3878 10.833 14.1668V13.7502Z"
                        fill="#444444"
                      />
                    </svg>
                  </div> -->
            <div
                    @click="slotsStore.openDialog('all')"
                    class="bg-white shadow-lg rounded-full whitespace-nowrap mb-4 py-2 px-4 text-pink-500 flex items-center cursor-pointer"
            >
                <span>Записаться</span>
                <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="ml-2.5"
                >
                    <path
                            d="M9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 -2.89066e-07 9 -3.93402e-07C6.61305 -4.97739e-07 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 -2.89066e-07 6.61305 -3.93402e-07 9C-4.97739e-07 11.3869 0.948211 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM5.0625 9.5625C4.91332 9.5625 4.77024 9.50324 4.66475 9.39775C4.55926 9.29226 4.5 9.14918 4.5 9C4.5 8.85082 4.55926 8.70774 4.66475 8.60225C4.77024 8.49676 4.91332 8.4375 5.0625 8.4375L11.5796 8.4375L9.16425 6.02325C9.05863 5.91763 8.99929 5.77437 8.99929 5.625C8.99929 5.47563 9.05863 5.33237 9.16425 5.22675C9.26987 5.12113 9.41313 5.06179 9.5625 5.06179C9.71187 5.06179 9.85513 5.12113 9.96075 5.22675L13.3357 8.60175C13.3881 8.654 13.4297 8.71607 13.4581 8.78441C13.4864 8.85275 13.501 8.92601 13.501 9C13.501 9.07399 13.4864 9.14725 13.4581 9.21559C13.4297 9.28393 13.3881 9.346 13.3357 9.39825L9.96075 12.7733C9.85513 12.8789 9.71187 12.9382 9.5625 12.9382C9.41313 12.9382 9.26987 12.8789 9.16425 12.7733C9.05863 12.6676 8.99929 12.5244 8.99929 12.375C8.99929 12.2256 9.05863 12.0824 9.16425 11.9767L11.5796 9.5625L5.0625 9.5625Z"
                            fill="#D4026E"
                    />
                </svg>
            </div>
        </div>
        <div
                class="xs:w-16 xs:h-16 w-20 h-20 overflow-hidden rounded-full bg-white shadow-lg rounded-full z-20 mt-2 relative flex items-center justify-center cursor-pointer"
                :class="{ 'btn-active': opened }"
        >
            <transition name="fade" mode="out-in">
                <div
                        class="btn-trigger absolute z-10"
                        @click="openList"
                        v-html="currIcon"
                        :style="{ opacity: changeIcon ? 0 : 1 }"
                        style="transition: opacity 1s ease-in-out;"

                ></div>
            </transition>
            <div class="cross-icon absolute" @click="closeList">
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
import {useSlots} from "../../store/slots";

export default {
    setup() {
        const slotsStore = useSlots();
        return {
            slotsStore,
        };
    },
    data() {
        return {
            opened: false,
            currScroll: 0,
            hideLimit: 300,
            changeIcon: false,
            icons: [

                '\n' +
                '<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<g clip-path="url(#clip0_411_4053)">\n' +
                '<rect width="80" height="80" rx="40" fill="white"/>\n' +
                '<path d="M47.288 67.2621C52.8845 67.4929 58.6165 66.3277 64.2869 64.9344C65.5931 64.6098 66.9377 64.2614 68.1209 63.3963C70.0727 61.9692 71.2875 59.3244 71.6951 56.759C72.1123 54.1877 71.8257 51.6576 71.483 49.2013C70.7923 44.24 68.977 34.3792 68.977 34.3792C67.1804 29.5791 64.2457 25.4537 60.3477 23.0309C56.4497 20.6081 51.5501 19.9962 46.9392 21.8027C44.5 22.7663 42.1905 24.3573 39.7439 25.2996C37.4909 26.1605 35.1372 26.4605 32.9931 27.7539C30.849 29.0472 28.8949 31.8331 29.3242 34.4045C29.9359 38.1094 27.7584 42.0538 27.0129 45.9527C25.8485 52.0472 28.3604 57.8512 32.0926 61.3966C36.1969 65.3084 41.6943 67.023 47.288 67.2621Z" fill="#DAF2EF"/>\n' +
                '<path d="M52.236 45.404L44 47C38.436 44.208 35 41 33 36L34.54 27.74L31.63 20H24.128C21.872 20 20.096 21.864 20.434 24.094C21.274 29.66 23.754 39.754 31 47C38.61 54.61 49.572 57.912 55.604 59.226C57.934 59.732 60 57.916 60 55.53V48.362L52.236 45.404Z" stroke="#2D2A34" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                '</g>\n' +
                '<defs>\n' +
                '<clipPath id="clip0_411_4053">\n' +
                '<rect width="80" height="80" rx="40" fill="white"/>\n' +
                '</clipPath>\n' +
                '</defs>\n' +
                '</svg>\n',
                '\n' +
                '<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<g clip-path="url(#clip0_411_4056)">\n' +
                '<rect width="80" height="80" rx="40" fill="white"/>\n' +
                '<path d="M57.2375 12.219C52.5289 9.8987 46.9718 10.1658 42.2085 12.3412C38.1421 14.1965 32.4663 18.6291 35.2175 23.7483C36.242 25.658 37.345 26.9248 37.0227 29.3199C35.5693 40.1418 44.686 45.0748 52.7009 49.5216C55.5604 51.1043 58.648 52.7425 61.9156 52.4113C65.3491 52.0607 68.2568 49.5458 70.1255 46.6293C73.8755 40.7515 74.111 33.0195 71.4353 26.6537C68.7592 20.2782 63.4222 15.2691 57.2375 12.219Z" fill="#F2DEDA"/>\n' +
                '<path d="M50 41C50.2652 41 50.5196 40.8946 50.7071 40.7071C50.8946 40.5196 51 40.2652 51 40C51 39.7348 50.8946 39.4804 50.7071 39.2929C50.5196 39.1054 50.2652 39 50 39C49.7348 39 49.4804 39.1054 49.2929 39.2929C49.1054 39.4804 49 39.7348 49 40C49 40.2652 49.1054 40.5196 49.2929 40.7071C49.4804 40.8946 49.7348 41 50 41ZM40 41C40.2652 41 40.5196 40.8946 40.7071 40.7071C40.8946 40.5196 41 40.2652 41 40C41 39.7348 40.8946 39.4804 40.7071 39.2929C40.5196 39.1054 40.2652 39 40 39C39.7348 39 39.4804 39.1054 39.2929 39.2929C39.1054 39.4804 39 39.7348 39 40C39 40.2652 39.1054 40.5196 39.2929 40.7071C39.4804 40.8946 39.7348 41 40 41ZM30 41C30.2652 41 30.5196 40.8946 30.7071 40.7071C30.8946 40.5196 31 40.2652 31 40C31 39.7348 30.8946 39.4804 30.7071 39.2929C30.5196 39.1054 30.2652 39 30 39C29.7348 39 29.4804 39.1054 29.2929 39.2929C29.1054 39.4804 29 39.7348 29 40C29 40.2652 29.1054 40.5196 29.2929 40.7071C29.4804 40.8946 29.7348 41 30 41Z" fill="#2D2A34" stroke="#2D2A34" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                '<path d="M40 60C51.046 60 60 51.046 60 40C60 28.954 51.046 20 40 20C28.954 20 20 28.954 20 40C20 43.642 20.974 47.06 22.676 50L21 59L30 57.324C33.0391 59.0821 36.489 60.0053 40 60Z" stroke="#2D2A34" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                '</g>\n' +
                '<defs>\n' +
                '<clipPath id="clip0_411_4056">\n' +
                '<rect width="80" height="80" rx="40" fill="white"/>\n' +
                '</clipPath>\n' +
                '</defs>\n' +
                '</svg>\n',
                '\n' +
                '<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<rect width="80" height="80" rx="40" fill="white"/>\n' +
                '<path d="M44.995 29.032C43.6775 23.5931 41.2749 18.4987 38.7174 13.5406C38.1255 12.3998 37.5055 11.2302 36.5403 10.3907C34.948 9.00586 32.637 8.74037 30.6008 9.22111C28.5576 9.69468 26.7328 10.8284 24.9784 11.9908C21.4345 14.3371 14.5086 19.4243 14.5086 19.4243C11.3804 22.7752 9.06237 26.987 8.2803 31.5433C7.49824 36.0996 8.34371 41.0003 10.9295 44.8032C12.3033 46.8123 14.1141 48.4841 15.4739 50.5075C16.7209 52.3731 17.5735 54.5257 19.1165 56.1401C20.6594 57.7545 23.2734 58.6801 25.0911 57.3958C27.7121 55.5517 31.2561 56.298 34.3843 55.6881C39.274 54.7338 42.9659 50.3569 44.6357 45.5781C46.4816 40.3187 46.3055 34.4708 44.995 29.032Z" fill="#DAF2EF"/>\n' +
                '<path d="M42 58H26C24.9391 58 23.9217 57.5786 23.1716 56.8284C22.4214 56.0783 22 55.0609 22 54V36M22 36H58M22 36V28C22 26.9391 22.4214 25.9217 23.1716 25.1716C23.9217 24.4214 24.9391 24 26 24H30M58 36V42M58 36V28C58 26.9391 57.5786 25.9217 56.8284 25.1716C56.0783 24.4214 55.0609 24 54 24H53M46 24V20M46 24V28M46 24H37M30 20V28M45.984 52H51.984M51.984 52H58M51.984 52V46M51.984 52V58" stroke="#2D2A34" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                '</svg>\n'
            ],
            currIndex: 0,
            currIcon: "",
            chatVisible: false,
            jivo_api: null,
            jivoStatus: "",
        };
    },
    methods: {
        setScroll() {
            this.opened = false;
            // this.jivo_api?.close();
            this.currScroll = window.scrollY;
            return this.currScroll;
        },
        openList() {
            this.opened = true;
            return this.opened;
        },
        closeList() {
            this.opened = false;
            return this.opened;
        },
        toggleList() {
            this.opened = !this.opened;
            return this.opened;
        },
        runJivo() {
            const checkJivoInterval = setInterval(() => {
                if (window?.jivo_api) {
                    clearInterval(checkJivoInterval);
                    this.jivoStatus = window?.jivo_api.chatMode();
                    this.jivo_api = window?.jivo_api;
                    window.jivo_onClose = async () => {
                        await this.closeJivo()
                    };
                    window.jivo_onMessageReceived = async () => {
                        await this.openJivo()
                    };
                }
            }, 500)
        },
        async openJivo() {
            await this.jivo_api?.open();
            document.body.classList.remove("helper-closed");
        },
        async closeJivo() {
            this.opened = false;
            setTimeout(() => {
                document.body.classList.add("helper-closed");
            }, 100)
        },
        updateIcon() {
            this.changeIcon = !this.changeIcon;

            setTimeout(() => {
                if (this.currIndex < this.icons.length - 1) {
                    this.currIndex += 1;
                } else {
                    this.currIndex = 0;
                }

                this.currIcon = this.icons[this.currIndex];

                this.changeIcon = !this.changeIcon;
            }, 1000);
        },
        startInterval() {
            this.intervalId = setInterval(() => {
                this.updateIcon();
            }, 3000);
        },
        stopInterval() {
            clearInterval(this.intervalId);
        },
    },
    computed: {
        scrolled() {
            return this.currScroll >= this.hideLimit;
        },
    },
    mounted() {
        this.currIcon = this.icons[0];

        this.startInterval();


        window.addEventListener("scroll", this.setScroll);

        this.runJivo();
        document.body.classList.add("helper-closed");
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.setScroll);
        this.startInterval();
    },
}

</script>

<style lang="scss">
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(16px);
  }
  to {
    opacity: 1;
    transform: translate(0px);
  }
}

.active {
  opacity: 1;
}

.helper-widget {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.4s ease, transform 0.6s ease;
  pointer-events: none;

  &_visible {
    opacity: 1;
    transform: none;
    pointer-events: all;
  }
}

.helper-list div {
  animation: fadeIn 0.25s ease-out 1 forwards;
}

@for $i from 0 through 4 {
  .helper-list div:nth-child(#{$i}) {
    animation-delay: #{(4 - $i) * 0.1}s;
  }
}

.btn-trigger {
  transition: opacity 0.8s ease, transform 0.5s ease;
}

.btn-active {
  .btn-trigger {
    pointer-events: none;
    opacity: 0;
    transform: scale(0);
  }

  .cross-icon {
    opacity: 1;
  }
}

.helper-list div {
  opacity: 0;
}

.cross-icon {
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  div {
    background: #c9c4c3;
    width: 24px;
    height: 2px;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.35s ease;
  }
}

.btn-active {
  .cross-icon {
    div {
      &:nth-child(1) {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:nth-child(2) {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}

#jvLabelWrap,
.__jivoMobileButton {
  opacity: 0 !important;
  pointer-events: none;
  display: none !important;
}

.helper-closed #jcont {
  opacity: 0 !important;
  pointer-events: none;
  display: none !important;
}

.__jivoMobileButton {
  height: 1px;
  width: 1px;
}
</style>
