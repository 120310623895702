<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    v-if="uiStore.errorModal"
    aria-live="assertive"
    class="fixed z-30 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start bg-opacity-90 bg-orange-50"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="uiStore.errorModal"
          class="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5"
        >
          <div class="w-0 flex-1 p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0 pt-0.5">
                <img
                  class="mx-auto min-w-[3.5rem] w-14 h-14 mr-1 sm:h-14 sm:mr-auto sm:my-4 sm:w-14 rounded-full lg:w-14 lg:h-14 object-cover object-top"
                  :src="img"
                  alt=""
                />
              </div>
              <div class="ml-3 w-0 flex-1">
                <p class="text-sm">Служба поддержки</p>
                <div class="mt-1 text-sm font-light">
                  <p>Извините, произошла ошибка.</p>
                  <p>Мы уже исправляем ее.</p>
                  <p>Перезагрузите страницу или продолжите.</p>
                </div>
                <div class="flex mt-2">
                  <div
                    @click="reload()"
                    class="inline-flex text-white items-center text-xs uppercase justify-center bg-pink-600 rounded-full py-4 px-4 mb md:mb-0 font-medium md:tracking-wider cursor-pointer"
                  >
                    Перезагрузить
                  </div>
                  <div
                    @click="uiStore.errorModal = false"
                    class="inline-flex ml-2 items-center text-xs uppercase justify-center border border-pink-600 rounded-full py-4 px-4 mb md:mb-0 font-medium md:tracking-wider text-pink-600 bg-white hover:text-black transition-colors cursor-pointer"
                  >
                    Продолжить
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { useUi } from "../store/ui";
import img from "@/assets/img/docs/placeholders/woman_1.png";

export default {
  setup() {
    const uiStore = useUi();
    // uiStore.openError();
    return {
      img,
      uiStore,
    };
  },
  methods: {
    reload() {
      location.reload();
    },
  },
};
</script>
