<template>
    <div>
        {{ service.attributes.title }}
    </div>
</template>
<script>
import {usePrices} from "~/store/price";

export default {
    props: ["serviceId"],
    setup(props) {
        const priceStore = usePrices();
        const service = priceStore.getPriceById(props.serviceId);
        return {
            service,
        }
    },
}
</script>