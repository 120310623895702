<template>
    <div class="flex p-8 flex-col gap-2 items-center relative bg-transparent">
        <AppUiIconAttention/>

        <div class="text-center mb-8 font-light leading-5 text-sm text-[#292b33]">Ой, вот это ажиотаж!<br>
            Разобрали все свободные слоты.<br/>
            <template v-if="appStore.clinicCalendar">
                Измените клинику или:
            </template>
        </div>

        <AppUiBtn type="white" text="Заявка в лист ожидания" @click="orderOpen"/>
        <AppUiBtn text="Выбрать другого врача" @click="navStore.step = 2"/>
    </div>
</template>

<script>
import {useNav} from "~/store/app/nav";
import {useApp} from "~/store/app/app";
import {useOrder} from "~/store/app/order";

export default {
    setup() {
        const navStore = useNav();
        const appStore = useApp();
        const orderStore = useOrder();

        return {
            navStore,
            appStore,
            orderStore
        }
    },
    methods: {
        orderOpen() {
            this.appStore.mode = 'order';
            this.orderStore.comment = 'Пустой график: ';
            switch (this.appStore.type) {
                case 'doc':
                    this.orderStore.comment += 'врач ' + this.appStore.hotDoc.name;
                    break;
                case 'special':
                    this.orderStore.comment += 'специальность ' + this.appStore.hotSpecial.title;
                    break;
                case 'service':
                    this.orderStore.comment += 'услуга ' + this.appStore.hotService.title;
                    break;
            }
        }
    }
}
</script>