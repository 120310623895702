<template>
    <div>
        <AppUiHeader title="Пациент"/>
        <AppStep6Form class="mb-8"/>
        <AppUiHeader title="О вас заботится"/>
        <AppStep6About class="mb-8"/>
        <AppStep6Promo class="mb-8"/>
        <AppStep6Btn/>
    </div>
</template>
<script>
export default {
}
</script>