<template>
    <div class="flex w-full items-center">
        <div
                @click="open = true"
                class="bg-orange-50 py-4 px-6 rounded-full w-full inline-flex items-center justify-center text-xs text-gray-500 hover:text-pink-500 focus:outline-none cursor-pointer"
        >
            <template v-if="clinicsStore.clinicId">
        <span class="w-full text-center">
          <span class="text-pink-500">{{
              clinicsStore.getRegionByClinicId(clinicsStore.clinicId)
              }}</span>
          <span class="block text-black">{{
              clinicsStore.getClinicById(clinicsStore.clinicId).attributes.address
              }}</span>
        </span>
                <ChevronDownIcon
                        :class="['ml-1 h-4 w-4 group-hover:text-gray-500']"
                        aria-hidden="true"
                />
            </template>
        </div>
        <TransitionRoot as="template" :show="open">
            <Dialog
                    focus
                    as="div"
                    class="fixed z-30 inset-0 overflow-y-auto"
                    @close="open = false"
            >
                <div
                        class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
                >
                    <TransitionChild
                            as="template"
                            enter="ease-out duration-300"
                            enter-from="opacity-0"
                            enter-to="opacity-100"
                            leave="ease-in duration-200"
                            leave-from="opacity-100"
                            leave-to="opacity-0"
                    >
                        <DialogOverlay
                                class="fixed inset-0 bg-red-100 bg-opacity-75 transition-opacity z-30 backdrop-blur-xl"
                        />
                    </TransitionChild>

                    <!-- This element is to trick the browser into centering the modal contents. -->
                    <span
                            class="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                    >&#8203;</span
                    >
                    <TransitionChild
                            as="template"
                            enter="ease-out duration-300"
                            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enter-to="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leave-from="opacity-100 translate-y-0 sm:scale-100"
                            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                                class="z-30 relative inline-block align-bottom bg-white w-full max-w-xl rounded-3xl px-6 pt-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:py-12 sm:px-6"
                        >
                            <div>
                                <!--                                            <div-->
                                <!--                                                class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">-->
                                <!--                                                <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true"/>-->
                                <!--                                            </div>-->
                                <div class="absolute right-5 top-6" @click="open = false">
                                    <XIcon
                                            :class="[
                      'h-5 w-5 md:h-6 md:w-6 text-black/30 hover:text-gray-500',
                    ]"
                                            aria-hidden="true"
                                    />
                                </div>
                                <div class="mt-3 md:text-center sm:mt-5">
                                    <DialogTitle
                                            as="h3"
                                            class="text-2xl sm:text-3xl leading-6 font-light text-gray-900"
                                    >
                                        Выберите свой город
                                    </DialogTitle>
                                    <div class="mt-2 md:mt-5">
                                        <p class="text-sm sm:text-base text-neutral-500/40 hidden">
                                            Клиника Фомина представлена в 4 регионах РФ
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                    class="mt-5 sm:mt-12 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense"
                            >
                                <div
                                        class="mb-6 md:mb-2"
                                        v-for="(r, idx) of ['ufa', 'krasnodar', 'msk', 'sochi', 'spb', 'perm']"
                                        :key="idx"
                                        :set="(region = clinicsStore.regionsWithClinics[r])"
                                >
                                    <div class="mb-4 text-sm md:text-base flex md:flex-nowrap flex-wrap justify-between max-w-sm mx-auto w-full items-start">
                                      <span class="text-xl md:text-2xl font-medium flex items-center w-full md:w-auto">
                                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none"
                                             xmlns="http://www.w3.org/2000/svg" class="mr-2">
                                          <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M6.5 5.5C3.46243 5.5 1 3.03757 1 0L0 0C0 2.70226 1.64899 5.01938 3.9956 6C1.64899 6.98062 0 9.29774 0 12H1C1 8.96243 3.46243 6.5 6.5 6.5V5.5Z"
                                                  fill="#343434"
                                          />
                                        </svg>
                                        {{ listRegions[r] }}
                                      </span>
                                        <span
                                                class="hover:text-pink-500 flex flex-col justify-end items-end mt-2"
                                                v-for="clinic of region"
                                                :key="clinic.id"
                                                @click="
                                                    clinicsStore.setClinicId(clinic.attributes.clinicId);
                                                    cookieHelper.setRegionId(r);
                                                    goHome();
                                              ">
                                        >
                      {{ clinic.attributes.address }}
                    </span>
                                    </div>
                                </div>
                                <div
                                        class="mb-6 md:mb-2"
                                        v-for="(r, idx) of []"
                                        :key="idx"
                                        :set="(region = clinicsStore.regionsWithClinics[r])"
                                >
                                    <div
                                            v-for="clinic of region"
                                            class="text-sm md:text-base flex md:flex-nowrap flex-wrap justify-between max-w-sm mx-auto w-full items-start"
                                            :key="clinic.id"
                                    >
                    <span
                            class="text-xl md:text-2xl font-light flex items-center w-full md:w-auto"
                    >
                      <svg
                              width="7"
                              height="12"
                              viewBox="0 0 7 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="mr-2"
                      >
                        <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.5 5.5C3.46243 5.5 1 3.03757 1 0L0 0C0 2.70226 1.64899 5.01938 3.9956 6C1.64899 6.98062 0 9.29774 0 12H1C1 8.96243 3.46243 6.5 6.5 6.5V5.5Z"
                                fill="#343434"
                        />
                      </svg>
                      <span class="opacity-50 font-medium">{{
                          listRegions[r]
                          }}</span>

                      <span
                              v-if="clinic.attributes?.comingsoon"
                              class="md:hidden ml-2 bg-[#F29425] px-2 rounded-br-xl rounded-tl-xl uppercase text-white text-[8px] leading-[1.25rem] font-light inline-flex justify-start"
                      >
                        Скоро открытие
                      </span>
                    </span>
                                        <span class="flex flex-col justify-end items-end mt-2">
                      <span class="opacity-50">{{
                          clinic.attributes.address
                          }}</span>
                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<script>
import {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    PopoverOverlay,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import {ChevronDownIcon, XIcon} from "@heroicons/vue/solid";
import {useClinics} from "~/store/clinics";
import cookieHelper from "~/libs/region";

export default {
    data() {
        return {
            open: false,
        };
    },
    async setup() {
        const clinicsStore = useClinics();

        if (!clinicsStore.listClinics.length) {
            await clinicsStore.fetchClinics();
        }

        const regionsWithClinics = clinicsStore.regionsWithClinics;
        const listRegions = clinicsStore.listRegions;

        return {
            clinicsStore,
            regionsWithClinics,
            listRegions,
            cookieHelper
        };
    },
    components: {
        ChevronDownIcon,
        XIcon,
        Popover,
        PopoverButton,
        PopoverGroup,
        PopoverOverlay,
        PopoverPanel,
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    },

    mounted() {
        this.getClinicId();
    },
    methods: {
        goHome() {
            window.location = window.location;
        },
        getClinicId() {
            if (!this.cookieHelper.hasRegionId()) {
                window.localStorage.removeItem("clinicId");
            }

            if (window.localStorage) {
                let clinicId = window.localStorage.getItem("clinicId");

                if (clinicId === '43c0eb72-7224-11ed-953e-be947ca7f82c') {
                    clinicId = '2fa0c897-bbd8-11ea-b901-dcf5058b959a';
                    window.localStorage.setItem('clinicId', clinicId);
                }

                if (clinicId) {
                    this.clinicsStore.setClinicId(clinicId);
                    this.clinicId = clinicId;
                } else {
                    this.open = true;
                }
            }
        },
    },
};
</script>
