<template>
    <div>
        <div class="rounded-2xl border border-[#efedf2] p-4 flex flex-col gap-2 items-start relative w-full bg-white">
            <div class="flex gap-2 items-start self-stretch relative w-full bg-transparent">
                <div class="relative w-12 h-12 bg-transparent">
                    <img v-if="doc.photoMiniSrc" :src="doc.photoMiniSrc"
                         class="rounded-[40px] border-2 border-white w-12 h-12 bg-primary-10"/>
                    <img v-else :src="docsStore.getPhotoPlaceholder(doc)"
                         class="mx-auto min-w-12 w-full h-full rounded-full object-cover object-top"/>
                </div>
                <div class="flex flex-col gap-0 justify-center items-start flex-1 relative w-full bg-transparent"><p
                        class="font-medium leading-5 text-sm text-[#292b33]">{{ doc.name }}</p>
                    <p class="font-light leading-5 text-sm text-[#9e9ba3]">{{ doc?.special }}</p>
                </div>
            </div>
            <div class="w-full h-px bg-[#efedf2]"></div>
            <div class="pl-14 pr-0 flex flex-col gap-0 items-start relative bg-transparent">
                <p class="font-medium leading-5 text-sm text-[#292b33]"
                   v-if="appStore.hotCost">
                    {{ numberWithSpaces(appStore.hotCost) }}&nbsp;₽
                </p>
                <p class="font-light leading-5 text-sm text-[#292b33]"
                   v-if="appStore.hotPrice">
                    {{ appStore.hotPrice.attributes.title}}
                </p>
                <p class="font-light leading-5 text-sm text-[#9e9ba3]">{{ clinic?.attributes?.address }}</p>
                <p class="font-medium leading-5 text-sm text-[#292b33]">{{ dateTime }}</p></div>
        </div>
    </div>
</template>

<script>
import {useApp} from "~/store/app/app";
import {createDateText} from "~/libs/date";
import {numberWithSpaces} from "../../../../libs/ui";
import {useDocs} from "~/store/docs";

export default {
    methods: {numberWithSpaces},
    setup() {
        const appStore = useApp();
        const docsStore = useDocs();
        return {
            appStore,
            docsStore,
            doc: appStore.hotDoc,
            clinic: appStore.hotClinic,
            dateTime: `${createDateText(appStore.date)}, ${appStore.time}`,
        }

    }
}
</script>