import {acceptHMRUpdate, defineStore} from "pinia";
import {useEmail} from "~/store/email";

export const useOrder = defineStore("order", {
    state: () => {
        return {
            status: null,
            name: "",
            phone: "",
            comment: "",
        };
    },
    getters: {},
    actions: {
        async send() {
            try {
                const mailStore = useEmail();

                const payload = {
                    fio: this.name,
                    phone: this.phone,
                    comment: this.comment,
                }

                await mailStore.send('order', payload);
            } catch (e) {
                console.error(e);
            }
        }
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useOrder, import.meta.hot));
}
