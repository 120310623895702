<template>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.104 2.5H23.896C22.098 2.5 20.6 2.5 19.412 2.66C18.156 2.828 17.022 3.2 16.112 4.11C15.2 5.022 14.828 6.156 14.66 7.41C14.5 8.6 14.5 10.1 14.5 11.896V12.052C15.414 12.022 16.41 12.01 17.5 12.004V12C17.5 10.072 17.504 8.776 17.634 7.81C17.758 6.888 17.972 6.492 18.234 6.232C18.494 5.972 18.888 5.758 19.81 5.632C20.776 5.504 22.072 5.5 24 5.5C25.928 5.5 27.224 5.504 28.19 5.634C29.112 5.758 29.508 5.972 29.768 6.234C30.028 6.494 30.242 6.888 30.368 7.81C30.496 8.776 30.5 10.072 30.5 12V12.004C31.5002 12.0062 32.5003 12.0222 33.5 12.052V11.896C33.5 10.1 33.5 8.6 33.34 7.412C33.172 6.156 32.8 5.022 31.888 4.112C30.978 3.2 29.844 2.828 28.588 2.66C27.4 2.5 25.9 2.5 24.104 2.5Z"
              fill="#72E0D8"></path>
        <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd"
              d="M4 28C4 20.458 4 16.686 6.344 14.344C8.686 12 12.458 12 20 12H28C35.542 12 39.314 12 41.656 14.344C44 16.686 44 20.458 44 28C44 35.542 44 39.314 41.656 41.656C39.314 44 35.542 44 28 44H20C12.458 44 8.686 44 6.344 41.656C4 39.314 4 35.542 4 28ZM25.5 25C25.5 24.6022 25.342 24.2206 25.0607 23.9393C24.7794 23.658 24.3978 23.5 24 23.5C23.6022 23.5 23.2206 23.658 22.9393 23.9393C22.658 24.2206 22.5 24.6022 22.5 25V26.5H21C20.6022 26.5 20.2206 26.658 19.9393 26.9393C19.658 27.2206 19.5 27.6022 19.5 28C19.5 28.3978 19.658 28.7794 19.9393 29.0607C20.2206 29.342 20.6022 29.5 21 29.5H22.5V31C22.5 31.3978 22.658 31.7794 22.9393 32.0607C23.2206 32.342 23.6022 32.5 24 32.5C24.3978 32.5 24.7794 32.342 25.0607 32.0607C25.342 31.7794 25.5 31.3978 25.5 31V29.5H27C27.3978 29.5 27.7794 29.342 28.0607 29.0607C28.342 28.7794 28.5 28.3978 28.5 28C28.5 27.6022 28.342 27.2206 28.0607 26.9393C27.7794 26.658 27.3978 26.5 27 26.5H25.5V25Z"
              fill="#72E0D8"></path>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M24 36C26.1217 36 28.1566 35.1571 29.6569 33.6569C31.1571 32.1566 32 30.1217 32 28C32 25.8783 31.1571 23.8434 29.6569 22.3431C28.1566 20.8429 26.1217 20 24 20C21.8783 20 19.8434 20.8429 18.3431 22.3431C16.8429 23.8434 16 25.8783 16 28C16 30.1217 16.8429 32.1566 18.3431 33.6569C19.8434 35.1571 21.8783 36 24 36ZM25.5 25C25.5 24.6022 25.342 24.2206 25.0607 23.9393C24.7794 23.658 24.3978 23.5 24 23.5C23.6022 23.5 23.2206 23.658 22.9393 23.9393C22.658 24.2206 22.5 24.6022 22.5 25V26.5H21C20.6022 26.5 20.2206 26.658 19.9393 26.9393C19.658 27.2206 19.5 27.6022 19.5 28C19.5 28.3978 19.658 28.7794 19.9393 29.0607C20.2206 29.342 20.6022 29.5 21 29.5H22.5V31C22.5 31.3978 22.658 31.7794 22.9393 32.0607C23.2206 32.342 23.6022 32.5 24 32.5C24.3978 32.5 24.7794 32.342 25.0607 32.0607C25.342 31.7794 25.5 31.3978 25.5 31V29.5H27C27.3978 29.5 27.7794 29.342 28.0607 29.0607C28.342 28.7794 28.5 28.3978 28.5 28C28.5 27.6022 28.342 27.2206 28.0607 26.9393C27.7794 26.658 27.3978 26.5 27 26.5H25.5V25Z"
              fill="#72E0D8"></path>
    </svg>
</template>
<script setup lang="ts">
</script>