<template>
    <div>
        <div class="mb-4">
            <div class="font-light leading-5 text-sm text-black mb-1" :class="{'text-[#D52B1E]':checkError('name')}">
                Фамилия Имя Отчество<span v-show="checkError('name')"> - обязательное поле</span>
            </div>
            <input v-model="patientStore.patient.name" type="text"
                   class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
                   placeholder="Введите имя полностью"
                   :class="{'border-[#D52B1E]':checkError('name')}">
        </div>
        <div class="mb-4">
            <div class="font-light leading-5 text-sm text-black mb-1"
                 :class="{'text-[#D52B1E]':checkError('bday') || checkError('age')}">
                Дата рождения<span v-if="checkError('bday')"> - обязательное поле</span><span
                    v-else-if="checkError('age')"> не соответствует выбору на первом экране. <span class="cursor-pointer underline" @click="navStore.step = 1">Вернитесь назад</span> и измените выбор.</span>
            </div>
            <input type="text" class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
                   placeholder="дд.мм.гггг"
                   v-maska="'##.##.####'"
                   v-model="patientStore.patient.bday"
                   @blur="blurBday"
                   :class="{'border-[#D52B1E]':checkError('bday') || checkError('age')}"
            >
        </div>
        <div class="mb-4">
            <div class="font-light leading-5 text-sm text-black mb-1" :class="{'text-[#D52B1E]':checkError('phone')}">
                Телефон<span v-show="checkError('phone')"> - обязательное поле</span>
            </div>
            <input class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
                   v-maska="'+7 (###) ###-##-##'"
                   placeholder="+7 (___) __-__-__"
                   type="text"
                   name="phone"
                   id="phone"
                   v-model="patientStore.patient.phone"
                   :class="{'border-[#D52B1E]':checkError('phone')}"
            >
        </div>
        <div class="mb-4">
            <div class="font-light leading-5 text-sm text-black mb-1" :class="{'text-[#D52B1E]':checkError('reason')}">
                Причина обращения<span v-show="checkError('reason')"> - обязательное поле</span>
            </div>
            <textarea v-model="patientStore.patient.reason"
                      class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
                      :class="{'border-[#D52B1E]':checkError('reason')}"
                      placeholder="Опишите симптомы"/>
        </div>
    </div>
</template>
<script>
import {usePatient} from "~/store/app/patient";
import {add20ToDate} from "~/libs/ui";
import {useNav} from "~/store/app/nav";

export default {
    setup() {
        const patientStore = usePatient();
        const navStore = useNav();

        return {
            patientStore,
            navStore
        }
    },
    methods: {
        checkError(checkField) {
            const listErorr = this.patientStore.checkValid;
            return this.patientStore.error && listErorr.findIndex(field => field === checkField) !== -1;
        },
        blurBday() {
            this.patientStore.patient.bday = add20ToDate(this.patientStore.patient.bday);
        }
    },

}
</script>
