<template>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5"
              d="M4 24.408C4 19.83 4 17.542 5.04 15.646C6.076 13.748 7.974 12.572 11.768 10.216L15.768 7.734C19.778 5.244 21.784 4 24 4C26.216 4 28.22 5.244 32.232 7.734L36.232 10.216C40.026 12.572 41.924 13.748 42.962 15.646C44 17.542 44 19.83 44 24.406V27.45C44 35.25 44 39.152 41.656 41.576C39.314 44 35.542 44 28 44H20C12.458 44 8.686 44 6.344 41.576C4 39.152 4 35.252 4 27.45V24.408Z"
              fill="#72E0D8"></path>
        <path d="M18.8941 30.796C18.5745 30.5589 18.1738 30.4585 17.7802 30.5168C17.3865 30.5751 17.0322 30.7874 16.7951 31.107C16.558 31.4266 16.4575 31.8273 16.5158 32.2209C16.5742 32.6146 16.7865 32.9689 17.1061 33.206C19.0969 34.6935 21.5149 35.4981 24.0001 35.5C26.4852 35.4981 28.9033 34.6935 30.8941 33.206C31.2139 32.9689 31.4265 32.6145 31.485 32.2206C31.5435 31.8268 31.4432 31.4259 31.2061 31.106C30.969 30.7862 30.6145 30.5736 30.2207 30.5151C29.8269 30.4566 29.4259 30.5569 29.1061 30.794C27.6328 31.8988 25.8416 32.4973 24.0001 32.5C22.1588 32.4978 20.3676 31.9001 18.8941 30.796Z"
              fill="#72E0D8"></path>
    </svg>
</template>
<script setup lang="ts">
</script>