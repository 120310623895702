<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="8" fill="#72E0D8"></circle>
        <path d="M12 8L12 13" stroke="white" stroke-width="2" stroke-linecap="round"></path>
        <circle cx="12" cy="16" r="1" fill="white"></circle>
        <circle cx="12" cy="16" r="1" stroke="white"></circle>
    </svg>
</template>
<script>
export default {
    setup() {
        return {}
    }

}
</script>