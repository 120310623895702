<template>
    <div>
        <div class="mt-3 text-center sm:mt-5">
            <div class="mt-4" v-if="slotsStore.typeVisit === 'visit'">
                <img
                        src="@/assets/img/status/success-image.jpg"
                        class="max-w-full w-36 mb-10 mx-auto"
                        alt=""
                />
                <div class="text-4xl mb-2">Заявка успешно отправлена!</div>
                <div class="text-xl text-black/40 mb-3 font-light">
                    В ближайшее время с вами свяжется сотрудник колл-центра
                </div>
            </div>
            <div v-else-if="slotsStore.type === 'home'">
                <img
                        src="@/assets/img/status/success-image.jpg"
                        class="max-w-full w-36 mb-10 mx-auto"
                        alt=""
                />
                <div class="text-4xl mb-2">Спасибо!</div>
                <div class="text-xl text-black/40 mb-3 font-light">
                    Ваша заявка прилетела к нам так же быстро, как приезжают наши педиатры.
                    Мы уже ищем способ включить адрес в зону выездов.
                    А пока будем рады видеть вас на приеме в клинике
                    <br/>
                    <br/>
                    <NuxtLink to="/docs" @click="slotsStore.order = false;">Команда спокойствия мам, пап и маленьких гениев 💜</NuxtLink>
                </div>
            </div>
        </div>

        <div class="flex items-end space-x-4">
            <div
                    class="cursor-pointer mt-3 mx-auto w-60 border-pink-600 text-pink-600 border border-pink-600 rounded-full py-4 px-8 flex uppercase items-center justify-center text-sm font-medium hover:text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    @click="
          slotsStore.order = false;
          programStore.order = false;
        "
            >
                Готово
            </div>
        </div>
    </div>
</template>

<script>
import {createDateText, createTimeText} from "../../libs/date";
import {useSlots} from "../../store/slots";
import {usePrograms} from "../../store/programs";

export default {
    setup() {
        const slotsStore = useSlots();
        const programStore = usePrograms();
        return {
            slotsStore,
            programStore,
            createDateText,
            createTimeText,
        };
    },
};
</script>
