<template>
  <svg
    viewBox="0 0 284 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="w-[180px] md:w-36 lg:w-[200px] h-full"
  >
    <path
      d="M118.51 27.85H120.19V41.68H118.51V27.85ZM122.46 34.35L128.94 41.68H126.79L120.53 34.47L126.6 27.85H128.64L122.46 34.35Z"
      fill="#242523"
    />
    <path
      d="M142.26 29.43H137.24L137 32.04C136.19 40.83 134.33 41.96 132.16 41.68L132.18 40C133.17 40.1 134.83 39.6 135.46 30.61L135.66 27.84H143.94V41.67H142.26V29.43Z"
      fill="#242523"
    />
    <path
      d="M151.22 41.68V27.85H152.9V38.92L161.3 27.85H162.78V41.68H161.1V30.62L152.7 41.68H151.22Z"
      fill="#242523"
    />
    <path
      d="M179.67 35.42H172.06V41.68H170.38V27.85H172.06V33.84H179.67V27.85H181.35V41.68H179.67V35.42Z"
      fill="#242523"
    />
    <path
      d="M188.79 41.68V27.85H190.47V38.92L198.87 27.85H200.35V41.68H198.67V30.62L190.28 41.68H188.79Z"
      fill="#242523"
    />
    <path
      d="M207.64 27.85H209.32V41.68H207.64V27.85ZM211.59 34.35L218.07 41.68H215.92L209.66 34.47L215.73 27.85H217.77L211.59 34.35Z"
      fill="#242523"
    />
    <path
      d="M226.46 27.77H227.9L233.85 41.68H232.05L230.27 37.49H223.97L222.21 41.68H220.51L226.46 27.77ZM224.65 35.91H229.63L227.14 30L224.65 35.91Z"
      fill="#242523"
    />
    <path
      d="M123.52 65.67H123.5C119.61 65.67 116.94 63.71 116.94 60.22C116.94 56.74 119.57 54.79 123.52 54.77V53.35H125.2V54.77C129.19 54.79 131.84 56.79 131.84 60.22C131.84 63.7 129.13 65.67 125.26 65.67H125.2V67.49H123.52V65.67ZM123.5 64.13H123.52V56.32C120.52 56.32 118.72 57.78 118.72 60.21C118.72 62.79 120.6 64.13 123.5 64.13ZM125.2 56.33V64.14H125.24C128.2 64.14 130.06 62.82 130.06 60.23C130.06 57.8 128.3 56.34 125.22 56.34H125.2V56.33Z"
      fill="#242523"
    />
    <path
      d="M143.52 53.31C147.53 53.31 150.57 56.21 150.57 60.42C150.57 64.53 147.53 67.53 143.52 67.53C139.51 67.53 136.47 64.63 136.47 60.42C136.47 56.41 139.41 53.31 143.52 53.31ZM143.52 65.95C146.68 65.95 148.8 63.54 148.8 60.42C148.8 57.2 146.49 54.89 143.52 54.89C140.52 54.89 138.24 57.2 138.24 60.42C138.25 63.56 140.56 65.95 143.52 65.95Z"
      fill="#242523"
    />
    <path
      d="M156.17 53.5H157.87L163.07 60.6L168.05 53.5H169.65V67.33H167.97V56.15L163.07 63.03H162.81L157.85 56.21V67.33H156.17V53.5Z"
      fill="#242523"
    />
    <path
      d="M176.81 67.34V53.5H178.49V64.57L186.89 53.5H188.37V67.34H186.69V56.27L178.29 67.34H176.81Z"
      fill="#242523"
    />
    <path
      d="M205.1 61.07H197.49V67.34H195.81V53.5H197.49V59.49H205.1V53.5H206.78V67.34H205.1V61.07Z"
      fill="#242523"
    />
    <path
      d="M217.63 53.42H219.07L225.02 67.33H223.22L221.44 63.14H215.14L213.38 67.33H211.68L217.63 53.42ZM215.81 61.57H220.79L218.3 55.66L215.81 61.57Z"
      fill="#242523"
    />
    <path
      d="M52.51 35.53C50.64 27.95 47.23 20.85 43.6 13.94C42.76 12.35 41.88 10.72 40.51 9.55001C38.25 7.62001 34.97 7.25001 32.08 7.92001C29.18 8.58001 26.59 10.16 24.1 11.78C19.07 15.05 9.24001 22.14 9.24001 22.14C4.80001 26.81 1.51001 32.68 0.400007 39.03C-0.709993 45.38 0.490007 52.21 4.16001 57.51C6.11001 60.31 8.68001 62.64 10.61 65.46C12.38 68.06 13.59 71.06 15.78 73.31C17.97 75.56 21.68 76.85 24.26 75.06C27.98 72.49 33.01 73.53 37.45 72.68C44.39 71.35 49.63 65.25 52 58.59C54.62 51.26 54.37 43.11 52.51 35.53Z"
      fill="#B0DCDB"
    />
    <path
      d="M111.83 18.28C109.78 10.95 104.16 5.22 97.27 2.2C91.39 -0.379998 81.22 -2.19 78.41 5.75C77.36 8.71 77.07 11.13 74.21 13.11C61.29 22.06 64.79 36.68 67.75 49.64C68.81 54.26 70.03 59.18 73.52 62.38C77.19 65.74 82.66 66.43 87.56 65.61C97.42 63.94 105.88 56.68 110.09 47.61C114.31 38.53 114.52 27.91 111.83 18.28Z"
      fill="#F2D6CE"
    />
    <path
      d="M59.22 81.32C37.36 81.32 19.58 63.54 19.58 41.68C19.58 19.83 37.36 2.04001 59.22 2.04001C81.08 2.04001 98.86 19.82 98.86 41.68C98.86 63.54 81.08 81.32 59.22 81.32ZM59.22 3.66001C38.25 3.66001 21.19 20.72 21.19 41.69C21.19 62.66 38.25 79.72 59.22 79.72C80.19 79.72 97.25 62.66 97.25 41.69C97.25 20.71 80.19 3.66001 59.22 3.66001Z"
      fill="#242523"
    />
    <path
      d="M59.22 75.06C58.66 75.06 58.16 74.78 57.86 74.31L55.19 70.04C54.88 69.55 54.87 68.92 55.15 68.41C55.43 67.9 55.97 67.59 56.55 67.59H61.88C62.46 67.59 63 67.91 63.28 68.41C63.56 68.92 63.55 69.54 63.24 70.04L60.57 74.31C60.29 74.78 59.78 75.06 59.22 75.06ZM61.88 69.19L56.55 69.2L59.23 73.45L61.88 69.19Z"
      fill="#242523"
    />
    <path
      d="M85.91 59.55C84.56 59.55 83.47 58.45 83.47 57.11C83.47 55.76 84.56 54.67 85.91 54.67C87.26 54.67 88.35 55.77 88.35 57.11C88.35 58.46 87.26 59.55 85.91 59.55ZM85.91 56.28C85.45 56.28 85.08 56.65 85.08 57.11C85.08 57.57 85.45 57.94 85.91 57.94C86.37 57.94 86.74 57.57 86.74 57.11C86.74 56.65 86.37 56.28 85.91 56.28Z"
      fill="#242523"
    />
    <path
      d="M32.53 59.55C31.18 59.55 30.09 58.45 30.09 57.11C30.09 55.76 31.18 54.67 32.53 54.67C33.88 54.67 34.97 55.77 34.97 57.11C34.97 58.46 33.88 59.55 32.53 59.55ZM32.53 56.28C32.07 56.28 31.7 56.65 31.7 57.11C31.7 57.57 32.07 57.94 32.53 57.94C32.99 57.94 33.36 57.57 33.36 57.11C33.36 56.65 32.99 56.28 32.53 56.28Z"
      fill="#242523"
    />
    <path
      d="M248.64 64.16C247.4 64.32 246.22 64.61 245.06 65.03C244.89 61.42 244.81 57.24 244.86 53.63C244.87 53.06 244.34 52.78 243.84 52.85C242.13 53.1 240.42 53.42 238.73 53.79C238.39 53.86 238.13 54.23 238.14 54.57C238.17 57.18 238.21 60.06 238.24 62.67C238.25 63.28 238.26 64.16 238.27 64.77C238.27 64.94 238.28 65.12 238.28 65.29C238.27 65.36 238.26 65.4 238.26 65.44C238.01 65.46 237.63 65.36 237.43 65.34C236.11 65.22 234.8 65.09 233.49 64.97C232.91 64.92 232.65 65.52 232.71 65.99C233.06 68.33 233.3 70.67 233.46 73.03C233.53 74.06 235.14 74.07 235.07 73.03C234.93 70.91 234.7 68.79 234.41 66.68C235.68 66.82 236.95 66.97 238.22 67.03C238.79 67.06 239.37 66.94 239.7 66.42C240.03 65.89 239.89 65.1 239.88 64.51C239.84 61.6 239.8 58.14 239.76 55.23C240.92 54.99 242.08 54.77 243.25 54.58C243.22 58.27 243.29 62.53 243.49 66.22C243.52 66.87 244.14 67.17 244.7 66.92C245.68 66.47 246.71 66.14 247.77 65.93C247.65 67.96 247.54 69.99 247.41 72.02C247.35 73.05 248.96 73.05 249.02 72.02C249.17 69.67 249.29 67.33 249.44 64.98C249.47 64.57 249.05 64.11 248.64 64.16Z"
      fill="#D4026E"
    />
    <path
      d="M257.82 67.28C256.62 67.64 255.4 67.92 254.17 68.17C254.15 67.03 254.03 62.88 254 61.94C255.12 61.74 256.24 61.6 257.38 61.54C258.41 61.49 258.42 59.87 257.38 59.93C256.23 59.99 255.08 60.13 253.95 60.32C253.92 59.31 253.89 55.84 253.85 54.84L257.91 54.46C258.93 54.37 258.94 52.76 257.91 52.85C256.28 53 254.65 53.15 253.02 53.3C252.6 53.34 252.2 53.64 252.22 54.1C252.4 58.83 252.52 64.4 252.58 69.14C252.59 69.7 253.09 70 253.6 69.92C255.17 69.65 256.72 69.28 258.25 68.83C259.24 68.53 258.82 66.98 257.82 67.28Z"
      fill="#D4026E"
    />
    <path
      d="M270.8 53.71C268.04 53.39 265.29 53.08 262.53 52.76C261.5 52.64 261.51 54.25 262.53 54.37C263.59 54.49 264.65 54.61 265.71 54.74C265.77 58.99 265.83 64.08 265.97 68.33C266.01 69.36 267.61 69.37 267.58 68.33C267.44 64.14 267.38 59.12 267.32 54.93C268.48 55.06 269.64 55.2 270.8 55.33C271.82 55.44 271.82 53.83 270.8 53.71Z"
      fill="#D4026E"
    />
    <path
      d="M281.51 53.57C281.46 55.74 281.45 58.74 281.42 60.9C279.94 62.53 278.52 64.21 277.07 65.87C276.89 62.08 276.72 57.45 276.54 53.66C276.49 52.63 274.88 52.62 274.93 53.66C275.15 58.13 275.34 63.43 275.55 67.9C275.56 68.18 275.7 68.38 275.88 68.51C276.13 68.73 276.48 68.8 276.79 68.57C276.89 68.52 276.97 68.44 277.04 68.34C278.52 66.71 279.94 65.03 281.39 63.37C281.37 65.24 281.32 67.11 281.31 68.98C281.3 70.02 282.91 70.02 282.92 68.98C282.95 64.12 283.02 58.42 283.12 53.56C283.14 52.53 281.53 52.53 281.51 53.57Z"
      fill="#D4026E"
    />
  </svg>
</template>
