<template>
    <div class="mb-3 relative" v-if="show">
        <Listbox v-model="selectedClinic">
            <div class="relative mt-1 z-10">
                <ListboxButton
                        class="w-full overflow-hidden rounded-2xl pl-4 pr-2 py-4 flex gap-0 items-center relative bg-[#f8f7fa]">
                    <span class="block truncate" :class="[selectedClinic?.title ? 'text-black' : 'text-gray-40']">
                        {{ selectedClinic?.title ? selectedClinic?.title : 'Все клиники' }}
                    </span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
                    </span>
                </ListboxButton>

                <transition
                        leave-active-class="transition duration-100 ease-in"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0">
                    <ListboxOptions
                            class="absolute mt-1 max-h-[50vh] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                        <ListboxOption
                                v-slot="{ active, selected }"
                                v-for="clinic in listClinics"
                                :key="clinic.title"
                                :value="clinic"
                                as="template">
                            <li :class="[
                                active ? 'bg-primary-40 text-black overflow-hidden rounded-2xl p-4 flex gap-2 items-center self-stretch relative w-full bg-[#bbefec]' : 'text-gray-900',
                                'relative cursor-default select-none py-2 px-5 pr-4',]">
                              <span :class="[
                                selected ? 'font-medium' : 'font-normal',
                                'block truncate font-light leading-6 text-base text-[#292b33]',]">
                                  {{ clinic.title }}
                              </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>

<script>
import {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
} from '@headlessui/vue'
import {CheckIcon, SelectorIcon} from "@heroicons/vue/solid";
import {usePatient} from "~/store/app/patient";
import {useClinics} from "~/store/clinics";
import {useApp} from "~/store/app/app";
import {useRegion} from "~/store/app/region";

export default {
    data() {
        return {
            selectedClinic: null,
        }
    },
    setup() {
        let show = false;

        const regionClinics = useRegion();
        const clinicStore = useClinics();
        const appStore = useApp();


        const listClinics = [
            {title: 'Все клиники', clinic: null},
        ]

        const targetClinics = [];

        switch (appStore.type) {
            case 'doc':
                const doc = appStore.hotDoc;
                doc.attributes.clinics.data.forEach((clinic) => {
                    targetClinics.push(clinic.attributes.clinicId)
                })
                break;
        }

        regionClinics.listClinicsRegion().forEach((clinicId) => {
            const clinic = clinicStore.getClinicById(clinicId)
            if (targetClinics.length && !targetClinics.includes(clinicId))
                return;

            listClinics.push({
                title: clinic.attributes.title,
                clinic: clinicId
            })
        })

        if (listClinics.length > 2) {
            show = true;
        } else {
            show = false;
        }

        return {
            appStore,
            listClinics,
            regionClinics,
            show
        }
    },
    components: {
        Listbox,
        ListboxLabel,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
        CheckIcon, SelectorIcon
    },
    watch: {
        selectedClinic(val) {
            if (this.appStore.clinicCalendar !== val.clinic) {
                this.appStore.clinicCalendar = val.clinic;
                this.appStore.clinicId = null;
                this.appStore.date = null;
                this.appStore.time = null;
            }
        }
    },
    mounted() {
        if (this.appStore.clinicCalendar !== null) {
            this.listClinics.forEach((clinic) => {
                if (clinic.clinic === this.appStore.clinicCalendar) {
                    this.appStore.date = null;
                    this.appStore.time = null;
                    this.selectedClinic = clinic;
                }
            })
        }
    }
}
</script>
