<template>
  <footer class="pt-8 lg:pt-16 bg-orange-50">
    <Container>
      <div class="grid grid-cols-1 lg:grid-cols-6 gap-6">
        <div class="col-span-3 xl:col-span-2 order-1 lg:order-2">
          <a
            :href="`tel:${clinic?.attributes?.phone}`"
            class="text-3xl font-light hover:text-pink-500 mb-2"
            >{{ clinic?.attributes?.phone }}</a
          >
          <div class="text-xl md:text-base text-black mt-2 font-light">
            <NuxtLink
              v-if="clinic?.attributes?.city"
              to="/contacts"
              v-html="`${clinic?.attributes?.address}`"
            ></NuxtLink>
          </div>
          <div
            class="text-base leading-6 mt-5 md:mt-8 max-w-full md:max-w-[18.75rem] font-medium"
          >
            <div class="text-black/40">Время работы</div>
            <HoursList :schedule="clinic?.attributes" />
          </div>
          <div class="space-y-4 hidden md:block">
            <div>Мы в социальных сетях</div>
            <ul class="flex space-x-8">
              <!--              <li>-->
              <!--                <a-->
              <!--                  href="#"-->
              <!--                  class="inline-block w-10 h-10 hover:scale-125 transition-transform"-->
              <!--                >-->
              <!--                  <img-->
              <!--                    src="@/assets/img/icons/whatsapp-social.svg"-->
              <!--                    class="w-full h-full object-contain"-->
              <!--                    alt=""-->
              <!--                  />-->
              <!--                </a>-->
              <!--              </li>-->
              <li>
                <a
                  href="https://t.me/fominclinic_kids"
                  target="_blank"
                  class="inline-block w-10 h-10 hover:scale-125 transition-transform"
                >
                  <img
                    src="@/assets/img/icons/telegram-social.svg"
                    class="w-full h-full object-contain"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://vk.com/fominclinic.kids"
                  target="_blank"
                  class="inline-block w-10 h-10 hover:scale-125 transition-transform"
                >
                  <img
                    src="@/assets/img/icons/vk-social.svg"
                    class="w-full h-full object-contain"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/fominclinic.kids/"
                  target="_blank"
                  class="inline-block w-10 h-10 hover:scale-125 transition-transform"
                >
                  <img
                    src="@/assets/img/icons/instagram-social.svg"
                    class="w-full h-full object-contain rounded-full"
                    alt=""
                  />
                </a>
              </li>
            </ul>
          </div>
          <div class="md:my-4">
            <div class="text-black/40 mb-1">Наша почта</div>
            <span>kids@fomin-clinic.ru</span>
          </div>
        </div>
        <div class="col-span-3 xl:col-span-4 order-2 lg:order-1">
          <div class="hidden lg:grid grid-cols-2 xl:grid-cols-3 gap-6">
            <div
              v-for="group of contentStore.footerMenu?.attributes?.Blocks"
              :key="group.id"
            >
              <div class="text-sm text-black/40 uppercase font-bold mb-4">
                {{ group.title }}
              </div>
              <ul class="space-y-4">
                <li v-for="item in group.Item" :key="item.id">
                  <NuxtLink :to="item.link">{{ item.title }}</NuxtLink>
                </li>
              </ul>
            </div>
          </div>
          <div class="block lg:hidden">
            <Disclosure
              v-for="group of contentStore.footerMenu?.attributes?.Blocks"
              :key="group.id"
              v-slot="{ open }"
            >
              <div class="py-4 px-2 border-b border-[#F0E0EB]">
                <DisclosureButton
                  class="flex w-full items-center justify-between hover:text-stone-500 uppercase text-sm md:text-base font-medium"
                >
                  <span class="text-black/60">{{ group.title }}</span>
                  <ChevronDownIcon
                    :class="open ? 'rotate-180 transform' : ''"
                    class="h-5 w-5 text-black/60"
                  />
                </DisclosureButton>
                <DisclosurePanel class="mt-6">
                  <ul>
                    <li v-for="item in group.Item" :key="item.id">
                      <NuxtLink
                        :to="item.link"
                        class="text-sm font-light mb-4 block"
                        >{{ item.title }}
                      </NuxtLink>
                    </li>
                  </ul>
                </DisclosurePanel>
              </div>
            </Disclosure>
          </div>
        </div>
      </div>
      <div
        class="text-center sm:text-left block md:flex justify-between sm:border-t py-6 mt-16 text-black/40"
      >
        <div class="text-base">
          <div class="mb-4 md:mb-0">© 2000–{{new Date().getFullYear()}} Клиника Фомина. Дети</div>
        </div>
        <div>
          <NuxtLink to="/info/documents" class="text-base hover:text-black"
            >Политика конфиденциальности
          </NuxtLink>
        </div>
          <div v-if="clinic?.attributes?.OOO">
              {{clinic.attributes.OOO}}
          </div>
      </div>
    </Container>
  </footer>
</template>

<script>
import { defineComponent, h } from "vue";

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import { useClinics } from "~/store/clinics";
import { useContent } from "../store/content";
import HoursList from "@/components/UI/HoursList.vue";

let navigation = {
  social: [
    {
      name: "Facebook",
      href: "#",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "Instagram",
      href: "#",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "Twitter",
      href: "#",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              d: "M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84",
            }),
          ]),
      }),
    },
  ],
};

export default {
  async setup() {
    const clinicsStore = useClinics();
    const contentStore = useContent();

    if (!contentStore.footerMenu) {
      await contentStore.fetchFooter();
    }

    let year = new Date().getFullYear();

    return {
      year,
      contentStore,
      clinic: computed(() => clinicsStore.getClinicById(clinicsStore.clinicId)),
    };
  },
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
    HoursList,
  },
};
</script>
