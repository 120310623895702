<template>
    <template v-if="!loader">
        <div class="overflow-auto w-full h-full p-4 pt-0" id="wrap_top">
            <div id="top" class="pb-4"></div>
            <template v-if="!navStore.error">
                <transition>
                    <AppStep1 v-if="navStore.step === 1"/> <!--age-->
                </transition>
                <transition>
                    <AppStep2 v-if="navStore.step === 2"/> <!--type-->
                </transition>
                <transition>
                    <AppStep3 v-if="navStore.step === 3"/> <!--list-->
                </transition>
                <transition>
                    <AppStep4 v-if="navStore.step === 4"/> <!--where/groupPrice-->
                </transition>
                <transition>
                    <AppStep5 v-if="navStore.step === 5"/> <!--calendar-->
                </transition>
                <transition>
                    <AppStep6 v-if="navStore.step === 6"/> <!--form-->
                </transition>
                <transition>
                    <AppStep7 v-if="navStore.step === 7"/> <!--final-->
                </transition>
                <div class="mt-3" v-if="navStore.checkNext()">
                    <AppUiBtn text="Продолжить" type="allow" @click="navStore.next()"/>
                </div>
            </template>
            <template v-else>
                <transition>
                    <AppError/>
                </transition>
            </template>
        </div>
    </template>
    <div v-else class="overflow-auto w-full h-full p-4 flex items-center justify-center">
        <AppLoader/>
    </div>
</template>

<script>

import {useNav} from "~/store/app/nav";
import {useRegion} from "~/store/app/region";
import {useGroups} from "~/store/app/priceGroup";
import {useDocs} from "~/store/docs";
import {usePrices} from "~/store/price";
import {useSpecials} from "~/store/specials";

export default {
    data() {
        return {
            loader: true,
        }
    },
    async setup() {
        const navStore = useNav();
        const regionStore = useRegion();
        const groupStore = useGroups();
        const docStore = useDocs();
        const priceStore = usePrices();
        const specialStore = useSpecials();

        const router = useRouter();
        watch(() => navStore.step, (newStep, oldStep) => {
            navStore.error = false;
            if (newStep !== oldStep && newStep > 0) {
                router.push({hash: '#' + newStep.toString()});
            }
        });

        watch(() => router.currentRoute.value.hash, (newHash, oldHash) => {
            navStore.error = false;
            const step = newHash.replace('#', '');
            if (!isNaN(step) && step !== navStore.step) {
                navStore.step = parseInt(step);
            }
        });

        regionStore.setRegion();

        return {
            navStore,
            regionStore,
            docStore,
            groupStore,
            priceStore,
            specialStore,
        }
    },
    async mounted() {
        this.navStore.step = 1;
        this.navStore.maxStep = 1;

        if (!this.docStore.listDocs.length) {
            await this.docStore.fetchDocs();
        }

        if (!this.priceStore.listPrices.length) {
            await this.priceStore.fetchPrices(true);
        }

        if (!this.groupStore.listGroups.length) {
            await this.groupStore.fetchGroups();
        }

        //конфликт с общей страницей врачей
        // if (!this.specialStore.listSpecials.length) {
        await this.specialStore.fetchSpecials2();
        // }

        this.loader = false;
    },
    unmounted() {
        if (window.location.hash) {
            let clean_url = window.location.protocol + "//" + window.location.host + window.location.pathname;
            window.history.replaceState({}, document.title, clean_url);
        }
    }
}
</script>

<style>
.v-enter-active {
    transition: opacity 0.6s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>