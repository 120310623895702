<template>
  <div>
    <ClientOnly>
      <UIVirtualHelper />
    </ClientOnly>
  </div>
</template>
<script>
export default {};
</script>
<style lang=""></style>
