<template>
    <div class="mb-3 rounded-2xl p-4 flex gap-4 items-center relative w-full"
         :class="{
            'bg-white md:hover:bg-[#f0fcfb] border-2': !active,
            'bg-[#f0fcfb] md:hover:bg-[#f0fcfb] border-2 border-primary-40': active,
            'opacity-50 cursor-not-allowed': disabled,
            'cursor-pointer': !disabled,
         }">
        <div class="px-2 relative w-full bg-transparent">
            <div class="flex flex-col gap-0 items-start flex-1 relative w-full bg-transparent"><p
                    class="font-medium leading-5 text-sm text-[#292b33]">{{ title }}</p></div>
            <div class="flex flex-col gap-0 items-start flex-1 relative w-full bg-transparent"><p
                    class="font-medium leading-5 text-xs text-[#292b33]">{{ subtitle }}</p></div>

        </div>
    </div>
</template>

<script>
export default {
    props: ["title", "subtitle", "active", "disabled"],
}
</script>