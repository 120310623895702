<template>
    <div
        v-if="show"
        class="fixed bg-white bg-opacity-95 left-0 right-0 bottom-0 transition-transform duration-500 lg:shadow-none shadow-xl z-10 py-4 md:py-5 text-center rounded-lg"
        style="border-top-left-radius: 50px; border-top-right-radius: 50px"
    >
        <div
            class="font-medium text-2xl sm:text-2xl lg:text-3xl leading-6 sm:leading-9 text-stone-700 my-4"
        >
            Ого!
            <br/>
            26 ноября заботимся о мамах!
        </div>
        <p class="mb-2 text-base px-8 text-black/50 font-light">
            А с детьми играют классные бебиситтеры ❤️
        </p>
        <div class="text-center">
            <NuxtLink
                to="/blog/kidsout"
                @click="hidePromo();"
                class="w-full cursor-pointer inline-flex text-white md:text-base items-center text-xs uppercase justify-center bg-pink-600 rounded-full py-4 mb-1 mt-4 md:py-6 md:px-10 px-4 mb md:mb-0 font-medium md:tracking-wider mx-2 max-w-[70%] md:max-w-xl"
            >
                Подробнее
            </NuxtLink>
            <a
                @click="hidePromo()"
                class="w-full cursor-pointer mt-2 inline-flex md:text-base items-center text-xs uppercase justify-center border border-pink-600 rounded-full py-4 md:py-6 md:px-10 px-4 mb md:mb-0 font-medium md:tracking-wider text-pink-600 bg-white hover:text-black transition-colors mx-2 max-w-[70%] md:max-w-xl"
            >
                Пропустить
            </a>
        </div>
    </div>
</template>

<script>
import {useApp} from "~/store/app/app";

export default {
    setup() {
        const appStore = useApp();
        return {
            appStore
        }
    },
    data() {
        return {
            show: false,
        };
    },
    mounted() {
        if (
            !window.localStorage.promoMomy
        ) {
            this.show = true;
        }
    },
    methods: {
        hidePromo() {
            window.localStorage.promoMomy = true;
            this.show = false;
        },
    },
};
</script>
