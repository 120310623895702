<template>
    <div class="items-center justify-center inline-block text-[9px] mx-1 px-2 bg-primary-50 border-black-50 border rounded-xl cursor-pointer"
         @click="openModal">
        <div
                type="button"
        >
            debug
        </div>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" @close="closeModal" class="relative z-10">
            <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black/25"/>
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div
                        class="flex min-h-full items-center justify-center p-4 text-center"
                >
                    <TransitionChild
                            as="template"
                            enter="duration-300 ease-out"
                            enter-from="opacity-0 scale-95"
                            enter-to="opacity-100 scale-100"
                            leave="duration-200 ease-in"
                            leave-from="opacity-100 scale-100"
                            leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                        >
                            <DialogTitle
                                    as="h3"
                                    class="text-lg font-medium leading-6 text-gray-900"
                            >
                                {{ doc.name }}
                            </DialogTitle>
                            <div class="mt-2">
                                <table class="text-sm text-gray-500">
                                    <tr>
                                        <td>Ссылка</td>
                                        <td>
                                            <a :href="`https://admin.fomin-kids.ru/admin/content-manager/collectionType/api::doc.doc/${doc.id}`"
                                               target="_blank">https://admin.fomin-kids.ru/admin/content-manager/collectionType/api::doc.doc/{{
                                                doc.id
                                                }}</a></td>
                                    </tr>
                                    <tr>
                                        <td>Специальность:</td>
                                        <td>{{ doc.special }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Активные услуги
                                        </td>
                                        <td>
                                            <a v-for="priceId of appStore.hotListPrices"
                                               :href="`https://admin.fomin-kids.ru/admin/content-manager/collectionType/api::price.price/${priceId}`">
                                                {{ priceId }}&nbsp;&nbsp;
                                            </a>
                                        </td>
                                    </tr>
                                    <tr v-if="doc.attributes.book">
                                        <td>Запись:</td>
                                        <td>
                                            <div v-for="book of doc.attributes.book" :key="book">
                                                <template
                                                        v-if="clinicId === book.clinic?.data?.attributes?.clinicId">
                                                    <b>{{ book.clinic?.data?.attributes?.clinicId }}</b><br>

                                                    <div v-for="pricetime of book.pricetime"
                                                         :class="{'font-bold': appStore.hotListPrices.findIndex(priceId => priceId === pricetime.price.data.id) !== -1}">
                                                        {{ pricetime.time }}
                                                        {{ pricetime.price.data.id }}
                                                        {{ pricetime.price.data.id }}
                                                        {{ pricetime.price.data.attributes.title }}
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    clinic: {{ book.clinic?.data?.attributes?.clinicId }}<br>
                                                </template>
                                            </div>


                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div class="mt-4">
                                <button
                                        type="button"
                                        class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        @click="closeModal"
                                >
                                    Ok
                                </button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>

import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from '@headlessui/vue'
import {useDocs} from "~/store/docs";
import {useClinics} from "~/store/clinics";
import {useApp} from "~/store/app/app";

export default {
    props: ["title", "docId", "clinicId"],
    data() {
        return {
            isOpen: false,
        }
    },
    setup(props) {
        const docStore = useDocs();
        const appStore = useApp();
        const doc = docStore.getDocById(props.docId);
        return {
            docStore, doc, appStore
        }
    },
    methods: {
        closeModal() {
            this.isOpen = false
        },
        openModal() {
            this.isOpen = true
        }
    },
    components: {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
    }
}
</script>
