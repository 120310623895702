<template>
    <div>
        <AppSearch type="doc"/>

        <div v-for="doc of appStore.dataFilter('doc')" class="mb-3" :key="doc.id">
            <AppCardDoc :docId="doc.id" :active="appStore.docId === doc.id"
                        @click="appStore.docId = doc.id; appStore.clearApp(navStore.step); navStore.next();"/>
        </div>
    </div>
</template>
<script>
import {useRegion} from "~/store/app/region";
import {useApp} from "~/store/app/app";
import {useNav} from "~/store/app/nav";
import {usePatient} from "~/store/app/patient";
import {useDocs} from "~/store/docs";

export default {
    setup: function () {
        const appStore = useApp();
        const navStore = useNav();

        return {
            appStore,
            navStore
        }
    },
}
</script>