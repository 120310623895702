<template></template>

<script>
import photo from "@/assets/img/constructor/image-home.jpg";
import { useUi } from "../store/ui";

export default {
  data() {
    return {
      url: null,
      icalendar: null,
    };
  },
  setup() {
    const uiStore = useUi();

    return {
      uiStore,
      photo,
    };
  },
  async mounted() {
    if (this.uiStore.slowInternet === null) {
      let start, end;
      start = new Date().getTime();
      await fetch(this.photo + "?" + new Date().getTime())
        .then((res) => {})
        .then((data) => {
          end = new Date().getTime();
        });

      // console.log("checkInternet", end - start);
      this.uiStore.slowInternet = end - start < 500 ? false : true;
    }
  },
  methods: {},
};
</script>
