<template>
    <div class="flex h-full justify-center items-center flex-col">
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                <circle cx="4" cy="12" r="3" fill="#72E0D8">
                    <animate id="svgSpinners3DotsBounce0" attributeName="cy"
                             begin="0;svgSpinners3DotsBounce1.end+0.375s"
                             calcMode="spline" dur="0.9s" keySplines=".33,.66,.66,1;.33,0,.66,.33" values="12;6;12"/>
                </circle>
                <circle cx="12" cy="12" r="3" fill="#72E0D8">
                    <animate attributeName="cy" begin="svgSpinners3DotsBounce0.begin+0.15s" calcMode="spline" dur="0.9s"
                             keySplines=".33,.66,.66,1;.33,0,.66,.33" values="12;6;12"/>
                </circle>
                <circle cx="20" cy="12" r="3" fill="#72E0D8">
                    <animate id="svgSpinners3DotsBounce1" attributeName="cy" begin="svgSpinners3DotsBounce0.begin+0.3s"
                             calcMode="spline" dur="0.9s" keySplines=".33,.66,.66,1;.33,0,.66,.33" values="12;6;12"/>
                </circle>
            </svg>
        </div>
        <div class="text-gray-40 text-center" style="width: 180px">
            {{ text ? text : "Формируем базу бережной заботы" }}
        </div>
    </div>


</template>
<script>
export default {
    props: ["text"]
}
</script>