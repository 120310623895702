<template>
    <div>
        <AppSearch type="service"/>

        <div v-for="service of appStore.dataFilter('service')" class="mb-3">
            <AppCardBtn :title="service.attributes.title"
                        @click="appStore.serviceId = service.id; appStore.clearApp(navStore.step); navStore.next()"
                        :active="appStore.serviceId === service.id"
            />
        </div>
    </div>
</template>
<script>
import {useRegion} from "~/store/app/region";
import {useApp} from "~/store/app/app";
import {useNav} from "~/store/app/nav";
import {usePatient} from "~/store/app/patient";
import {useDocs} from "~/store/docs";
import {usePrices} from "~/store/price";

export default {
    setup: function () {
        const appStore = useApp();
        const priceStore = usePrices();
        const navStore = useNav();

        return {
            appStore,
            navStore
        }
    },
}
</script>