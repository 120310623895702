<template>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5"
              d="M6 20C6 12.458 6 8.686 8.344 6.344C10.686 4 14.458 4 22 4H26C33.542 4 37.314 4 39.656 6.344C42 8.686 42 12.458 42 20V28C42 35.542 42 39.314 39.656 41.656C37.314 44 33.542 44 26 44H22C14.458 44 10.686 44 8.344 41.656C6 39.314 6 35.542 6 28V20Z"
              fill="#72E0D8"></path>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M24 10.5C24.3978 10.5 24.7794 10.658 25.0607 10.9393C25.342 11.2206 25.5 11.6022 25.5 12V14.5H28C28.3978 14.5 28.7794 14.658 29.0607 14.9393C29.342 15.2206 29.5 15.6022 29.5 16C29.5 16.3978 29.342 16.7794 29.0607 17.0607C28.7794 17.342 28.3978 17.5 28 17.5H25.5V20C25.5 20.3978 25.342 20.7794 25.0607 21.0607C24.7794 21.342 24.3978 21.5 24 21.5C23.6022 21.5 23.2206 21.342 22.9393 21.0607C22.658 20.7794 22.5 20.3978 22.5 20V17.5H20C19.6022 17.5 19.2206 17.342 18.9393 17.0607C18.658 16.7794 18.5 16.3978 18.5 16C18.5 15.6022 18.658 15.2206 18.9393 14.9393C19.2206 14.658 19.6022 14.5 20 14.5H22.5V12C22.5 11.6022 22.658 11.2206 22.9393 10.9393C23.2206 10.658 23.6022 10.5 24 10.5ZM14.5 28C14.5 27.6022 14.658 27.2206 14.9393 26.9393C15.2206 26.658 15.6022 26.5 16 26.5H32C32.3978 26.5 32.7794 26.658 33.0607 26.9393C33.342 27.2206 33.5 27.6022 33.5 28C33.5 28.3978 33.342 28.7794 33.0607 29.0607C32.7794 29.342 32.3978 29.5 32 29.5H16C15.6022 29.5 15.2206 29.342 14.9393 29.0607C14.658 28.7794 14.5 28.3978 14.5 28ZM16.5 36C16.5 35.6022 16.658 35.2206 16.9393 34.9393C17.2206 34.658 17.6022 34.5 18 34.5H30C30.3978 34.5 30.7794 34.658 31.0607 34.9393C31.342 35.2206 31.5 35.6022 31.5 36C31.5 36.3978 31.342 36.7794 31.0607 37.0607C30.7794 37.342 30.3978 37.5 30 37.5H18C17.6022 37.5 17.2206 37.342 16.9393 37.0607C16.658 36.7794 16.5 36.3978 16.5 36Z"
              fill="#72E0D8"></path>
    </svg>
</template>
<script setup lang="ts">
</script>