<template>
    <Popover class="relative pt-20 md:pt-0 bg-white z-20">
        <div
            class="absolute inset-0 bg-orange-50 pointer-events-none"
            aria-hidden="true"
        />
        <div
            class="fixed bg-orange-50 pb-3 md:pb-0 left-0 right-0 top-0 md:relative transition-transform duration-500 lg:shadow-none"
            :class="[
        {
          'header-hidden': direction === 1 && scrollY >= 200,
        },
        { 'shadow-md': scrollY >= 15 },
      ]"
        >
            <div
                class="hidden lg:flex justify-between py-1 px-6 border-b border-gray-200"
            >
                <nav class="space-x-6 text-xs">
                    <NuxtLink to="/about" class="hover:text-pink-500 py-1.5 inline-flex"
                    >О клинике
                    </NuxtLink>
                    <NuxtLink
                        :to="{ path: '/info/preparation' }"
                        class="hover:text-pink-500 py-1.5 inline-flex"
                    >Подготовка к приёму
                    </NuxtLink>
                    <NuxtLink
                        to="/mobile"
                        class="text-pink-500 py-1.5 inline-flex hover:underline"
                    >Скачать мобильное приложение
                    </NuxtLink>
                </nav>
                <div class="flex items-center text-xs">
                    <span class="mr-1">Ваш регион:</span>
                    <RegionClinic></RegionClinic>
                </div>
            </div>
            <div
                class="max-w-7xl mx-auto flex justify-between items-center px-3 py-5 pb-1 sm:px-6 sm:py-4 sm:pb-4 lg:px-8 space-x-4 lg:justify-start lg:space-x-10"
            >
                <div>
                    <NuxtLink to="/" class="flex" aria-label="Главная страница">
                        <Logo class="relative md:-left-4"/>
                    </NuxtLink>
                </div>
                <div class="flex items-center lg:hidden">
          <span
              class="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none mr-4"
          >
            <PhoneIcon
                class="h-6 w-6 text-[#323435] stroke-1"
                aria-hidden="true"
                @click="open = true"
            />
          </span>
                    <TransitionRoot as="template" :show="open">
                        <Dialog
                            as="div"
                            class="fixed z-30 inset-0 overflow-y-auto"
                            @close="open = false"
                        >
                            <div
                                class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
                            >
                                <TransitionChild
                                    as="template"
                                    enter="ease-out duration-300"
                                    enter-from="opacity-0"
                                    enter-to="opacity-100"
                                    leave="ease-in duration-200"
                                    leave-from="opacity-100"
                                    leave-to="opacity-0"
                                >
                                    <DialogOverlay
                                        class="fixed inset-0 bg-red-100 bg-opacity-75 transition-opacity z-200"
                                    />
                                </TransitionChild>

                                <!-- This element is to trick the browser into centering the modal contents. -->
                                <span
                                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                                    aria-hidden="true"
                                >&#8203;</span
                                >
                                <TransitionChild
                                    as="template"
                                    enter="ease-out duration-300"
                                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <div
                                        class="z-30 relative inline-block align-bottom bg-white rounded-3xl px-4 pt-12 pb-12 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                                    >
                                        <div>
                                            <!--                                            <div-->
                                            <!--                                                class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">-->
                                            <!--                                                <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true"/>-->
                                            <!--                                            </div>-->

                                            <div class="absolute right-5 top-6" @click="open = false">
                                                <XIcon
                                                    :class="[
                            'h-5 w-5 md:h-6 md:w-6 text-black/30 hover:text-gray-500',
                          ]"
                                                    aria-hidden="true"
                                                />
                                            </div>

                                            <div class="mt-3 text-center sm:mt-5">
                                                <DialogTitle
                                                    as="h3"
                                                    class="text-2xl leading-6 font-medium text-gray-900"
                                                >
                                                    Свяжитесь с нами
                                                </DialogTitle>
                                                <div class="mt-2">
                                                    <p class="text-base text-gray-400 hidden">
                                                        Наши специалисты 24 часа 7 дней в неделю на связи с
                                                        вами.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-10 sm:mt-6 sm:gap-3">
                                            <UISocialButtons/>
                                            <a
                                                :href="`tel:${clinic?.attributes?.phone}`"
                                                class="w-full inline-flex justify-center rounded-full border border-transparent shadow-sm font-light px-4 py-4 uppercase bg-pink-600 text-sm text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:col-start-2 sm:text-sm"
                                                @click="open = false"
                                            >
                                                Позвонить
                                            </a>
                                            <button
                                                type="button"
                                                class="mt-4 w-full inline-flex justify-center rounded-full border border-transparent shadow-sm font-light px-4 py-4 uppercase bg-[#F1D6CE] text-sm text-black hover:bg-[#F1D6CE/40] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-300 sm:col-start-2 sm:text-sm"
                                                @click="
                          slotsStore.openOrder()
                          // open = false;
                        "
                                                ref="cancelButtonRef"
                                            >
                                                Обратный звонок
                                            </button>
                                            <button
                                                type="button"
                                                class="mt-4 w-full inline-flex justify-center rounded-full border border-[#AEAEAE] shadow-sm font-light px-4 py-4 uppercase text-sm text-[#AEAEAE] hover:text-white hover:bg-[#AEAEAE] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#AEAEAE] sm:col-start-2 sm:text-sm"
                                                @click="open = false"
                                                ref="cancelButtonRef"
                                            >
                                                Отмена
                                            </button>
                                        </div>
                                    </div>
                                </TransitionChild>
                            </div>
                        </Dialog>
                    </TransitionRoot>

                    <PopoverButton
                        class="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none"
                    >
                        <span class="sr-only">Open menu</span>
                        <MenuIcon
                            class="h-7 w-7 text-[#323435] stroke-1"
                            aria-hidden="true"
                        />
                    </PopoverButton>
                    <PopoverOverlay class="fixed inset-0 bg-red-100 opacity-30"/>
                </div>
                <div
                    class="hidden lg:flex-1 lg:flex md:items-center md:justify-between"
                >
                    <PopoverGroup
                        as="nav"
                        class="flex space-x-3 lg:space-x-3 xl:space-x-9 items-center"
                    >
                        <Popover v-slot="{ open }">
                            <PopoverButton
                                :class="[
                  open ? 'text-gray-900' : 'text-black/80',
                  'group bg-orange-50 rounded-md inline-flex items-center text-sm xl:text-base font-medium hover:text-gray-900  focus:outline-none lg:min-w-[56px]',
                ]"
                            >
                                <span>Услуги</span>
                                <ChevronDownIcon
                                    :class="[
                    open ? 'text-gray-600' : 'text-black/80',
                    'ml-0.5 w-2 lg:h-4 h-2 lg:w-4 group-hover:text-gray-600',
                  ]"
                                    aria-hidden="true"
                                />
                            </PopoverButton>

                            <!--                            <TransitionRoot-->
                            <!--                                appear-->
                            <!--                                :show="open"-->
                            <!--                                enter="transition-opacity"-->
                            <!--                                enter-from="opacity-90"-->
                            <!--                                enter-to="opacity-100"-->
                            <!--                                leave="transition-opacity"-->
                            <!--                                leave-from="opacity-100"-->
                            <!--                                leave-to="opacity-90"-->
                            <!--                            >-->
                            <PopoverPanel
                                class="hidden lg:block absolute z-10 top-full inset-x-0 transform shadow-lg py-12 bg-white transition"
                            >
                                <Container>
                                    <div class="grid grid-cols-4 items-start">
                                        <div class="grid grid-cols-1 gap-5">
                                            <NuxtLink
                                                :to="link.url"
                                                v-for="link in subMenuNav"
                                                :key="link.url"
                                                class="text-base xl:text-2xl hover:text-pink-500"
                                                :class="{
                          'text-pink-500 hover:underline': link.active,
                        }"
                                            >
                                                <PopoverButton>{{ link.title }}</PopoverButton>
                                            </NuxtLink>
                                        </div>
                                        <div class="col-span-3">
                                            <div class="grid grid-cols-3 gap-6">
                                                <NuxtLink
                                                    :to="link?.url ? `/services/${link.url}` : `/develop`"
                                                    v-for="link in subMenuList"
                                                    :key="link.url"
                                                    class="hover:text-pink-500 text-sm xl:text-base"
                                                >
                                                    <PopoverButton>{{ link.title }}</PopoverButton>
                                                </NuxtLink>
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                                <!-- <div
                                                                                                                                                                                                                                                                  class="
                                                                                                                                                                                                                                                                    max-w-7xl
                                                                                                                                                                                                                                                                    mx-auto
                                                                                                                                                                                                                                                                    grid
                                                                                                                                                                                                                                                                    gap-y-6
                                                                                                                                                                                                                                                                    px-4
                                                                                                                                                                                                                                                                    py-6
                                                                                                                                                                                                                                                                    sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8
                                                                                                                                                                                                                                                                    lg:grid-cols-3 lg:px-8 lg:py-12
                                                                                                                                                                                                                                                                    xl:py-16
                                                                                                                                                                                                                                                                  "
                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                  <span
                                                                                                                                                                                                                                                                    v-for="(category, index) in listCategories"
                                                                                                                                                                                                                                                                    :key="category.attributes.title"
                                                                                                                                                                                                                                                                    class="-m-3 p-3 flex flex-col justify-between rounded-lg"
                                                                                                                                                                                                                                                                  >
                                                                                                                                                                                                                                                                    <div class="flex md:h-full lg:flex-col">
                                                                                                                                                                                                                                                                      <div class="flex-shrink-0">
                                                                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                                                                          class="
                                                                                                                                                                                                                                                                            inline-flex
                                                                                                                                                                                                                                                                            items-center
                                                                                                                                                                                                                                                                            justify-center
                                                                                                                                                                                                                                                                            h-10
                                                                                                                                                                                                                                                                            w-10
                                                                                                                                                                                                                                                                            rounded-md
                                                                                                                                                                                                                                                                            bg-pink-100
                                                                                                                                                                                                                                                                            text-indigo-500
                                                                                                                                                                                                                                                                            sm:h-12 sm:w-12
                                                                                                                                                                                                                                                                          "
                                                                                                                                                                                                                                                                        >
                                                                                                                                                                                                                                                                          <component
                                                                                                                                                                                                                                                                            :is="menuIcons[index].icon"
                                                                                                                                                                                                                                                                            class="h-6 w-6"
                                                                                                                                                                                                                                                                            aria-hidden="true"
                                                                                                                                                                                                                                                                          />
                                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                                      </div>
                                                                                                                                                                                                                                                                      <div
                                                                                                                                                                                                                                                                        class="
                                                                                                                                                                                                                                                                          ml-4
                                                                                                                                                                                                                                                                          md:flex-1 md:flex md:flex-col md:justify-between
                                                                                                                                                                                                                                                                          lg:ml-0 lg:mt-4
                                                                                                                                                                                                                                                                        "
                                                                                                                                                                                                                                                                      >
                                                                                                                                                                                                                                                                        <div>
                                                                                                                                                                                                                                                                          <p class="text-base font-medium text-gray-900">
                                                                                                                                                                                                                                                                            {{ category.attributes.title }}
                                                                                                                                                                                                                                                                          </p>
                                                                                                                                                                                                                                                                          <div
                                                                                                                                                                                                                                                                            v-for="service in category.attributes.menu"
                                                                                                                                                                                                                                                                            class="mt-1 text-sm text-gray-500"
                                                                                                                                                                                                                                                                          >
                                                                                                                                                                                                                                                                            <PopoverButton>
                                                                                                                                                                                                                                                                              <NuxtLink
                                                                                                                                                                                                                                                                                :to="`/services/${service.service.data.attributes.link}`"
                                                                                                                                                                                                                                                                              >
                                                                                                                                                                                                                                                                                {{ service.service.data.attributes.title }}
                                                                                                                                                                                                                                                                              </NuxtLink>
                                                                                                                                                                                                                                                                            </PopoverButton>
                                                                                                                                                                                                                                                                          </div>
                                                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                                                        <PopoverButton class="text-left mt-4">
                                                                                                                                                                                                                                                                          <NuxtLink
                                                                                                                                                                                                                                                                            class="
                                                                                                                                                                                                                                                                              mt-8
                                                                                                                                                                                                                                                                              text-sm
                                                                                                                                                                                                                                                                              font-medium
                                                                                                                                                                                                                                                                              text-indigo-600
                                                                                                                                                                                                                                                                              lg:mt-8
                                                                                                                                                                                                                                                                            "
                                                                                                                                                                                                                                                                            to="/services"
                                                                                                                                                                                                                                                                          >
                                                                                                                                                                                                                                                                            Смотреть все <span aria-hidden="true">&rarr;</span>
                                                                                                                                                                                                                                                                          </NuxtLink>
                                                                                                                                                                                                                                                                        </PopoverButton>
                                                                                                                                                                                                                                                                      </div>
                                                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                                                  </span>
                                                                                                                                                                                                                                                                </div> -->
                                <!-- <div class="bg-gray-50">
                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                    class="
                                                                                                                                                                                                                                                                      max-w-7xl
                                                                                                                                                                                                                                                                      mx-auto
                                                                                                                                                                                                                                                                      space-y-6
                                                                                                                                                                                                                                                                      px-4
                                                                                                                                                                                                                                                                      py-5
                                                                                                                                                                                                                                                                      sm:flex sm:space-y-0 sm:space-x-10 sm:px-6
                                                                                                                                                                                                                                                                      lg:px-8
                                                                                                                                                                                                                                                                    "
                                                                                                                                                                                                                                                                  >
                                                                                                                                                                                                                                                                    <div
                                                                                                                                                                                                                                                                      v-for="item in callsToAction"
                                                                                                                                                                                                                                                                      :key="item.name"
                                                                                                                                                                                                                                                                      class="flow-root"
                                                                                                                                                                                                                                                                    >
                                                                                                                                                                                                                                                                      <a
                                                                                                                                                                                                                                                                        :href="item.href"
                                                                                                                                                                                                                                                                        class="
                                                                                                                                                                                                                                                                          -m-3
                                                                                                                                                                                                                                                                          p-3
                                                                                                                                                                                                                                                                          flex
                                                                                                                                                                                                                                                                          items-center
                                                                                                                                                                                                                                                                          rounded-md
                                                                                                                                                                                                                                                                          text-base
                                                                                                                                                                                                                                                                          font-medium
                                                                                                                                                                                                                                                                          text-gray-900
                                                                                                                                                                                                                                                                          hover:bg-gray-100
                                                                                                                                                                                                                                                                        "
                                                                                                                                                                                                                                                                      >
                                                                                                                                                                                                                                                                        <component
                                                                                                                                                                                                                                                                          :is="item.icon"
                                                                                                                                                                                                                                                                          class="flex-shrink-0 h-6 w-6 text-gray-400"
                                                                                                                                                                                                                                                                          aria-hidden="true"
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                        <span class="ml-3">{{ item.name }}</span>
                                                                                                                                                                                                                                                                      </a>
                                                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                </div> -->
                            </PopoverPanel>
                            <!--                            </TransitionRoot>-->
                        </Popover>
                        <NuxtLink
                            to="/docs"
                            class="text-sm xl:text-base font-medium text-black/80 hover:text-gray-900 lg:min-w-[56px]"
                        >Врачи
                        </NuxtLink>
                        <NuxtLink
                            to="/price"
                            class="text-sm xl:text-base font-medium text-black/80 hover:text-gray-900 lg:min-w-[56px]"
                        >
                            Цены
                        </NuxtLink>
                        <NuxtLink
                            to="/blog"
                            class="text-sm xl:text-base font-medium text-black/80 hover:text-gray-900 lg:min-w-[56px]"
                        >
                            Блог
                        </NuxtLink>
                        <NuxtLink
                            to="/contacts"
                            class="text-sm xl:text-base font-medium text-black/80 hover:text-gray-900 lg:min-w-[56px]"
                        >
                            Контакты
                        </NuxtLink>
                    </PopoverGroup>
                    <div class="flex items-center lg:ml-12">
                        <a
                            href="#"
                            class="text-sm xl:text-base font-medium text-black/80 hover:text-pink-600"
                        >
                            {{ clinic?.attributes?.phone }}
                        </a>
                        <ClientOnly>
                            <div
                                @click="slotsStore.openHome()"
                                v-if="clinic?.attributes?.home || isTest()"
                                class="ml-4 inline-flex cursor-pointer items-center justify-center px-6 py-2 border border-pink-600 rounded-full text-sm xl:text-base font-medium text-pink-600 bg-white hover:text-black transition-colors"
                            >
                                Вызов на дом
                            </div>
                        </ClientOnly>
                        <div
                            @click="slotsStore.openDialog('all')"
                            class="ml-4 inline-flex cursor-pointer items-center justify-center px-6 py-2 border border-pink-600 rounded-full text-sm xl:text-base font-medium text-white bg-pink-600 hover:bg-pink-700 transition-colors"
                        >
                            Записаться
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <TransitionRoot appear>
            <TransitionChild
                enter="transition ease-in-out duration-400 transform opacity"
                enter-from="-translate-y-10 opacity-0"
                enter-to="translate-x-0 opacity-1"
                leave="transition ease-in-out duration-400 transform opacity"
                leave-from="translate-x-0 opacity-1"
                leave-to="-translate-y-10 opacity-0"
                class="fixed top-0 z-50 w-full right-0 left-0"
            >
                <PopoverPanel
                    focus
                    class="inset-x-0 transition transform origin-top-right lg:hidden"
                    v-slot="{ close }"
                >
                    <div
                        class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50 min-h-screen max-h-screen overflow-y-auto"
                    >
                        <div class="pt-4 pb-6 px-10 sm:pb-8">
                            <div class="flex items-center justify-between relative">
                                <div class="absolute -right-6 top-2">
                                    <PopoverButton
                                        class="rounded-md p-2 inline-flex items-center justify-center text-gray-400 outline-none"
                                    >
                                        <span class="sr-only">Close menu</span>
                                        <XIcon class="h-6 w-6" aria-hidden="true"/>
                                    </PopoverButton>
                                </div>
                            </div>
                            <div class="mt-6 sm:mt-8">
                                <nav>
                                    <div>
                                        <NuxtLink
                                            to="/"
                                            class="text-xl py-4 flex border-orange-500/10"
                                            @click="close"
                                        >Главная
                                        </NuxtLink>
                                    </div>
                                    <div>
                                        <Disclosure v-slot="{ open }">
                                            <div class="py-4 border-t border-orange-500/10 text-left">
                                                <DisclosureButton
                                                    class="flex w-full items-center justify-between hover:text-stone-500 text-xl"
                                                >
                                                    <span class="text-xl flex">Услуги</span>
                                                    <ChevronDownIcon
                                                        :class="open ? 'rotate-180 transform' : ''"
                                                        class="h-5 w-5 text-black/60"
                                                    />
                                                </DisclosureButton>
                                                <DisclosurePanel class="mt-6">
                                                    <ul>
                                                        <li
                                                            v-for="(link, idx) of subMenuNav"
                                                            :key="link.title"
                                                        >
                                                            <NuxtLink
                                                                :to="link.url"
                                                                class="text-sm text-left font-light mb-4 block w-full"
                                                                :class="
                                  idx === subMenuNav.length - 1
                                    ? 'text-pink-500'
                                    : ''
                                "
                                                                @click="close"
                                                            >{{ link.title }}
                                                            </NuxtLink>
                                                        </li>
                                                    </ul>
                                                </DisclosurePanel>
                                            </div>
                                        </Disclosure>
                                    </div>
                                    <div>
                                        <NuxtLink
                                            @click="close"
                                            to="/docs/"
                                            class="text-xl py-4 flex border-t border-orange-500/10 w-full"
                                        >Врачи
                                        </NuxtLink>
                                    </div>
                                    <div>
                                        <NuxtLink
                                            @click="close"
                                            to="/price/"
                                            class="text-xl py-4 flex border-t border-orange-500/10 w-full"
                                        >Цены
                                        </NuxtLink>
                                    </div>
                                    <div>
                                        <NuxtLink
                                            @click="close"
                                            to="/about/"
                                            class="text-xl py-4 flex border-t border-orange-500/10 w-full"
                                        >О нас
                                        </NuxtLink>
                                    </div>
                                    <div>
                                        <NuxtLink
                                            @click="close"
                                            to="/blog/"
                                            class="text-xl py-4 flex border-t border-orange-500/10 w-full"
                                        >Блог
                                        </NuxtLink>
                                    </div>
                                    <div>
                                        <NuxtLink
                                            @click="close"
                                            to="/contacts"
                                            class="text-xl py-4 flex border-t border-orange-500/10 w-full"
                                        >Контакты
                                        </NuxtLink>
                                    </div>
                                    <!-- <div class="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-3">
                                                                                                                                                                                                                                                                                              <div
                                                                                                                                                                                                                                                                                                v-for="(category, index) in listCategories"
                                                                                                                                                                                                                                                                                                :key="category.attributes.title"
                                                                                                                                                                                                                                                                                                class="
                                                                                                                                                                                                                                                                                                  -m-3
                                                                                                                                                                                                                                                                                                  flex
                                                                                                                                                                                                                                                                                                  items-start
                                                                                                                                                                                                                                                                                                  p-3
                                                                                                                                                                                                                                                                                                  rounded-lg
                                                                                                                                                                                                                                                                                                  hover:bg-gray-50
                                                                                                                                                                                                                                                                                                "
                                                                                                                                                                                                                                                                                              >
                                                                                                                                                                                                                                                                                                <div
                                                                                                                                                                                                                                                                                                  class="
                                                                                                                                                                                                                                                                                                    flex-shrink-0 flex
                                                                                                                                                                                                                                                                                                    items-center
                                                                                                                                                                                                                                                                                                    justify-center
                                                                                                                                                                                                                                                                                                    h-10
                                                                                                                                                                                                                                                                                                    w-10
                                                                                                                                                                                                                                                                                                    rounded-md
                                                                                                                                                                                                                                                                                                    bg-pink-100
                                                                                                                                                                                                                                                                                                    text-indigo-500
                                                                                                                                                                                                                                                                                                    sm:h-12 sm:w-12
                                                                                                                                                                                                                                                                                                  "
                                                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                                                  <component
                                                                                                                                                                                                                                                                                                    :is="menuIcons[index].icon"
                                                                                                                                                                                                                                                                                                    class="h-6 w-6"
                                                                                                                                                                                                                                                                                                    aria-hidden="true"
                                                                                                                                                                                                                                                                                                  />
                                                                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                                                                                <div class="ml-4 text-base font-medium text-gray-900">
                                                                                                                                                                                                                                                                                                  {{ category.attributes.title }}

                                                                                                                                                                                                                                                                                                  <div
                                                                                                                                                                                                                                                                                                    v-for="service in category.attributes.menu"
                                                                                                                                                                                                                                                                                                    class="mt-1 text-sm text-gray-500"
                                                                                                                                                                                                                                                                                                  >
                                                                                                                                                                                                                                                                                                    <PopoverButton>
                                                                                                                                                                                                                                                                                                      <NuxtLink
                                                                                                                                                                                                                                                                                                        :to="`/services/${service.service.data.attributes.link}`"
                                                                                                                                                                                                                                                                                                      >
                                                                                                                                                                                                                                                                                                        {{ service.service.data.attributes.title }}
                                                                                                                                                                                                                                                                                                      </NuxtLink>
                                                                                                                                                                                                                                                                                                    </PopoverButton>
                                                                                                                                                                                                                                                                                                  </div>
                                                                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                                                                              </div>
                                                                                                                                                                                                                                                                                            </div> -->
                                </nav>
                            </div>
                        </div>
                        <div class="pt-6 pb-12 px-10">
                            <div class="mb-4">
                                <PopoverButton class="w-full">
                                    <div
                                        @click="slotsStore.openDialog('all')"
                                        class="w-full cursor-pointer flex items-center justify-center px-4 py-4 border border-transparent rounded-full shadow-sm text-sm uppercase font-medium text-white bg-pink-600 hover:bg-pink-700"
                                    >
                                        Записаться
                                    </div>
                                </PopoverButton>
                            </div>
                            <ClientOnly>
                                <div class="mb-4" v-if="clinic?.attributes?.home || isTest()">
                                    <PopoverButton class="w-full">
                                        <div
                                            @click="slotsStore.openHome()"
                                            class="w-full cursor-pointer flex items-center justify-center px-4 py-4 border border-pink-600 rounded-full shadow-sm text-sm uppercase font-medium text-pink-600 border-pink-600 hover:bg-pink-700 hover:text-white"
                                        >
                                            Выезд на дом
                                        </div>
                                    </PopoverButton>
                                </div>
                            </ClientOnly>
                            <p class="mb-4 text-center text-base font-medium text-black">
                                {{ " " }}
                                <a href="#" class="text-xl hover:text-pink-500">
                                    {{ clinic?.attributes?.phone }}</a
                                >
                            </p>

                            <div class="flex justify-center mb-4">
                                <RegionMobileMenu class=""/>
                            </div>
                            <span class="hidden">
                <UISocialButtons/>
              </span>
                        </div>
                    </div>
                </PopoverPanel>
            </TransitionChild>
        </TransitionRoot>
    </Popover>
</template>

<script>
import {reactive, ref} from "vue";
import {useServices} from "~/store/services";
import {useClinics} from "~/store/clinics";
import {useSlots} from "../store/slots";

import {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    PopoverOverlay,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/vue";
import {
    BookmarkAltIcon,
    BriefcaseIcon,
    ChartBarIcon,
    CheckCircleIcon,
    CursorClickIcon,
    DesktopComputerIcon,
    InformationCircleIcon,
    MenuIcon,
    NewspaperIcon,
    OfficeBuildingIcon,
    PhoneIcon,
    ShieldCheckIcon,
    UserGroupIcon,
    XIcon,
    HomeIcon,
    ClipboardIcon,
    GlobeAltIcon,
} from "@heroicons/vue/outline";
import {ChevronDownIcon} from "@heroicons/vue/solid";

const menuIcons = [
    {
        icon: ClipboardIcon,
    },
    {
        icon: HomeIcon,
    },
    {
        icon: GlobeAltIcon,
    },
];
const callsToAction = [
    // {name: 'Watch Demo', href: '#', icon: PlayIcon},
    // {name: 'View All Products', href: '#', icon: CheckCircleIcon},
    // {name: 'Contact Sales', href: '#', icon: PhoneIcon},
];

const subMenuNav = [
    {
        title: "Приём педиатра",
        url: "/services/pediatr",
        active: false,
    },
    {
        title: "Годовые программы",
        url: "/programs/complex",
        active: false,
    },
    {
        title: "Вакцинация",
        url: "/vacc",
        active: false,
    },
    {
        title: "Вызов педиатра",
        url: "/services/calling_a_pediatrician",
        active: false,
    },
    {
        title: "Все услуги",
        url: "/services",
        active: true,
    },
];

export default {
    components: {
        Popover,
        PopoverButton,
        PopoverGroup,
        PopoverOverlay,
        PopoverPanel,
        ChevronDownIcon,
        PhoneIcon,
        MenuIcon,
        XIcon,
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
    },
    mounted() {
        this.getClinicId();
        window.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll);
    },
    async setup() {
        const open = ref(false);
        const servicesStore = useServices();
        const clinicsStore = useClinics();
        const slotsStore = useSlots();
        const route = useRoute();

        if (!servicesStore.listServices.length) {
            await servicesStore.fetchServices();
        }

        if (!servicesStore.listCategories.length) {
            await servicesStore.fetchCategory();
        }
        const listCategories = servicesStore.listCategories;

        return {
            slotsStore,
            clinicsStore,
            listCategories,
            menuIcons,
            callsToAction,
            subMenuNav,
            subMenuList: computed(() =>
                servicesStore.listServices
                    .filter((service) => {
                        const serviceEx = servicesStore.getServiceById(+service.id);

                        return (
                            service.attributes.menu &&
                            serviceEx?.attributes?.clinics?.data && serviceEx?.attributes?.clinics?.data?.findIndex(
                                (clinic) =>
                                    clinic.attributes.clinicId === clinicsStore.clinicId
                            ) !== -1
                        )
                    })
                    .map((service) => ({
                        title: service.attributes.title,
                        url: service.attributes.link,
                    }))
            ),
            open,
            clinic: computed(() => clinicsStore.getClinicById(clinicsStore.clinicId)),
            route,
        };
    },
    data() {
        return {
            clinicId: null,
            lastScrollY: 0,
            scrollY: 0,
            direction: 1,
        };
    },
    methods: {
        isTest() {
            if (window) {
                const currentDomain = window.location.hostname;
                const exampleDomain = "kids.fomin-clinic.ru";

                if (
                    currentDomain === exampleDomain ||
                    currentDomain.endsWith(`.${exampleDomain}`)
                ) {
                    return false;
                }

                return true;
            }
            return false;
        },
        onScroll() {
            if (window.innerWidth >= 768) return;
            this.lastScrollY = this.scrollY;
            this.scrollY = window.scrollY;
            this.direction = this.scrollY >= this.lastScrollY ? 1 : -1;
        },
        goHome() {
            window.location = window.location;
        },
        getClinicId() {
            if (window.localStorage) {
                let clinicId = window.localStorage.getItem("clinicId");
                if (clinicId === '43c0eb72-7224-11ed-953e-be947ca7f82c') {
                    clinicId = '2fa0c897-bbd8-11ea-b901-dcf5058b959a';
                    window.localStorage.setItem('clinicId', clinicId);
                }

                if (clinicId) {
                    if (!this.clinicsStore.getClinicById(clinicId)) {
                        !this.clinicsStore.unsetClinicId();
                        this.open = true;
                    } else {
                        this.clinicsStore.setClinicId(clinicId);
                        this.clinicId = clinicId;
                        // this.clinic = this.clinicsStore.getClinicById(clinicId);
                    }
                } else {
                    this.open = true;
                }
            }
        },
    },
};
</script>

<style lang="scss">
@media screen and (max-width: 767px) {
    .header-hidden {
        transform: translateY(-100%);
    }
}
</style>
