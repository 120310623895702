<template>
    <div>
        <div class="overflow-hidden rounded-2xl border-2 border-[#f8f7fa] px-6 py-8 flex flex-col gap-10 items-center relative w-[328px] bg-transparent"
             style="margin: 0 auto">
            <div class="flex flex-col gap-4 items-center relative bg-transparent">
                <div class="overflow-hidden relative w-[120px] h-[120px] bg-transparent">
                    <img src="/complite.png" alt="">
                </div>
                <p class="tracking-[0.14em] uppercase text-center leading-6 text-sm text-black">
                    Вы&nbsp;записались!</p></div>
            <div class="flex flex-col gap-6 items-end self-stretch relative w-full bg-transparent">
                <div class="flex gap-2 items-baseline justify-between self-stretch relative w-full bg-transparent">
                    <p class="font-light leading-6 text-base text-[#9e9ba3]">
                        Врач
                    </p>
                    <p class="text-right font-light leading-6 text-base text-black">
                        {{ hotDoc?.name }}
                    </p>
                </div>
                <div class="flex flex-col gap-1 items-end self-stretch relative w-full bg-transparent">
                    <div class="flex gap-2 items-start justify-between self-stretch relative w-full bg-transparent">
                        <p class="font-light leading-6 text-base text-[#9e9ba3]">Адрес</p>
                        <div class="flex gap-1 items-start relative bg-transparent">
                            <p class="text-right font-light leading-6 text-base text-black">
                                {{ hotClinic?.attributes?.address }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="flex gap-2 items-start justify-between self-stretch relative w-full bg-transparent">
                    <p class="font-light leading-6 text-base text-[#9e9ba3]">
                        Дата и&nbsp;время</p>
                    <p class="text-right font-light leading-6 text-base text-black">
                        {{ dateTime }}
                    </p>
                </div>
                <div class="flex gap-2 items-start justify-between  self-stretch relative w-full bg-transparent"  v-if="appStore.hotCost">
                    <p class="font-light leading-6 text-base text-[#9e9ba3]">
                        Стоимость
                    </p>
                    <p class="tracking-[-0.036em] text-right leading-6 text-lg text-black">
                        {{ numberWithSpaces(appStore.hotCost) }}&nbsp;₽
                    </p>
                </div>
            </div>
        </div>

        <AppStep7Attension/>
    </div>
</template>

<script>
import {useApp} from "~/store/app/app";
import {createDateText} from "~/libs/date";
import {usePrices} from "~/store/price";
import {numberWithSpaces} from "~/libs/ui";

export default {
    methods: {numberWithSpaces},
    setup() {
        const appStore = useApp();
        const hotDoc = appStore.hotDoc;
        const hotClinic = appStore.hotClinic;
        return {
            appStore,
            hotDoc,
            hotClinic,
            dateTime: `${createDateText(appStore.date)}, ${appStore.time}`,
        };
    }
}
</script>