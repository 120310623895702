<template>
    <div class="mb-4">
        <div class="rounded-2xl p-4 flex gap-2 items-center relative w-full bg-[#f8f7fa]">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="12" height="12" rx="6" transform="matrix(-1 0 0 1 17 4)" stroke="#292B33"
                      stroke-width="1.5" stroke-linecap="round"></rect>
                <path d="M15.5 14.5L19 18" stroke="#292B33" stroke-width="1.5" stroke-linecap="round"></path>
            </svg>
            <input v-if="type==='doc'" v-model="appStore.search.doc"
                   class="font-light w-full leading-6 text-base text-[#9e9ba3] focus:outline-none bg-transparent"
                   placeholder="Поиск врача по ФИО"/>
            <input v-else-if="type === 'service'" v-model="appStore.search.service"
                   class="font-light w-full leading-6 text-base text-[#9e9ba3] focus:outline-none bg-transparent"
                   placeholder="Поиск услуги"/>
            <input v-else-if="type === 'special'" v-model="appStore.search.special"
                   class="font-light w-full leading-6 text-base text-[#9e9ba3] focus:outline-none bg-transparent"
                   placeholder="Поиск специализации"/>
            <!-- Кнопка "очистить ввод" -->
            <button @click="clearInput" class="absolute right-2" v-show="showClear()">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="#292B33">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import {useApp} from "~/store/app/app";

export default {
    props: [
        'type'
    ],
    setup(props) {
        const appStore = useApp();
        const type = props.type;

        // Функция чистки ввода
        const clearInput = () => {
            if (type === 'doc') appStore.search.doc = '';
            else if (type === 'service') appStore.search.service = '';
            else if (type === 'special') appStore.search.special = '';
        }

        const showClear = () => {
            return appStore.search[type] !== null && appStore.search[type].length > 0;
        }

        return {
            appStore,
            clearInput,
            showClear
        }
    },
}
</script>