<template>
    <div class="cursor-pointer rounded-2xl border-2 md:hover:border-[#bbefec] md:hover:bg-[#f0fcfb] pl-4 pr-6 py-4 flex gap-4 items-center relative w-full"
         :class="active ? 'border-[#bbefec] bg-[#f0fcfb]': 'border-[#efedf2] bg-white'">
        <div class="flex gap-4 items-center flex-1 relative w-full bg-transparent">

            <AppUiIconDoc v-if="type === 'doc'"/>
            <AppUiIconSpecial v-if="type === 'special'"/>
            <AppUiIconService v-if="type === 'service'"/>
            <AppUiIconHome v-if="type === 'home'"/>

            <div class="flex flex-col gap-0 items-start flex-1 relative w-full bg-transparent">
                <p class="font-medium leading-5 text-base text-[#292b33]">{{ text.header }}</p>
                <p class="font-light leading-4 text-sm text-[#9e9ba3]">{{ text.description }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['type', 'active'],
    setup() {

    },
    computed: {
        text: function () {
            switch (this.type) {
                case 'doc':
                    return {
                        header: 'Знаю врача',
                        description: 'Например, Иванова Мария',
                    };
                case 'special':
                    return {
                        header: 'Знаю специальность',
                        description: 'Например, офтальмолог'
                    };
                case 'service':
                    return {
                        header: 'Нужна услуга',
                        description: 'Анализы, справки, вакцинация, УЗИ и другая диагностика'
                    }
                case 'home':
                    return {
                        header: 'Вызвать врача на дом',
                        description: 'Максимум комфорта. Врач приедет день в день и точно ко времени'
                    };
            }
        }
    }
}
</script>