<template>
    <div class="flex p-8 flex-col gap-2 items-center relative bg-transparent">
        <AppUiIconAttention/>
        <div class="text-center mb-8 font-light leading-5 text-sm text-[#292b33]">
            Упс :( <br/>
            Извините, произошла ошибка.<br/>
            Повторите попытку или оставьте заявку.
        </div>
        <AppUiBtn text="Повторить" @click="restart"/>
        <AppUiBtn type="white" text="Оставить заявку" @click="orderOpen"/>
    </div>
</template>

<script>
import {useNav} from "~/store/app/nav";
import {useOrder} from "~/store/app/order";
import {useApp} from "~/store/app/app";

export default {
    setup() {
        const navStore = useNav();
        const appStore = useApp();

        return {
            navStore,
            appStore
        }
    },
    methods: {
        restart() {
            this.navStore.error = false;
            this.navStore.prev();
        },
        orderOpen() {
            this.appStore.mode = 'order';
        }
    }
}
</script>
