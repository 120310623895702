<template>
  <div>
    <transition name="fade" mode="out-in">
      <div
        v-if="!show"
        class="loader bg-orange-50"
        style="height: 100vh; z-index: 30; position: fixed; width: 100%"
      ></div>
    </transition>
  </div>
</template>

<script>
import { useClinics } from "../store/clinics";

export default {
  setup() {
    const clinicStore = useClinics();

    return {
      show: computed(() => {
        return !!clinicStore.clinicId;
      }),
    };
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
