<template>
    <div>
        <AppSearch type="special"/>

        <div v-for="special of appStore.dataFilter('special')" class="mb-3">
            <div v-if="special.id">
                <AppCardBtn :title="special.attributes.title"
                            @click="appStore.specialId = special.id;  appStore.clearApp(navStore.step); navStore.next()"
                            :active="appStore.specialId === special.id"
                />
            </div>
        </div>
    </div>
</template>
<script>
import {useApp} from "~/store/app/app";
import {useNav} from "~/store/app/nav";
import {useSpecials} from "~/store/specials";

export default {
    setup: async function () {
        const appStore = useApp();
        const navStore = useNav();
        const specialStore = useSpecials();

        if (!specialStore.listSpecials?.length) {
            await specialStore.fetchSpecials();
        }


        return {
            appStore,
            specialStore,
            navStore
        }
    },
}
</script>