<template>
    <div id="top" class="pb-4"></div>
    <div class="mb-4">
        <div class="font-light leading-5 text-sm text-black mb-1">
            Ваше имя
        </div>
        <input v-model="orderStore.name" type="text"
               class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
               placeholder="Введите имя"
        >
    </div>
    <div class="mb-4">
        <div class="font-light leading-5 text-sm text-black mb-1">
            Телефон
        </div>
        <input class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
               v-maska="'+7 (###) ###-##-##'"
               placeholder="+7 (___) __-__-__"
               type="text"
               name="phone"
               id="phone"
               v-model="orderStore.phone"
        >
    </div>
    <div class="mb-4">
        <div class="font-light leading-5 text-sm text-black mb-1">
            Комментарий
        </div>
        <textarea
                v-model="orderStore.comment"
                class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
                placeholder=""/>
    </div>
    <div class="mt-3">
        <AppUiBtn text="Отправить" @click="send"/>
    </div>
</template>
<script>
import {useApp} from "~/store/app/app";
import {useOrder} from "~/store/app/order";

export default {
    setup() {
        const appStore = useApp();
        const orderStore = useOrder();

        return {
            appStore,
            orderStore
        }
    },
    mounted() {
        this.orderStore.name = this.appStore.patient.name.length ? this.appStore.patient.name : "";
        this.orderStore.phone = this.appStore.patient.phone.length ? this.appStore.patient.phone : "";
    },
    methods: {
        async send() {
            this.orderStore.status = true;
            await this.orderStore.send();
        }
    }
}
</script>
