<template>
    <div>
        {{ special.attributes.title }}
    </div>
</template>
<script>

import {useSpecials} from "~/store/specials";

export default {
    props: ["specialId"],
    setup(props) {
        const specialStore = useSpecials();
        const special = specialStore.getSpecialById(props.specialId);
        return {
            special,
        }
    },
}
</script>