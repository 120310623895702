<template>
    <div>
        <template v-if="loader">
            <transition>
                <AppLoader :text="text"/>
            </transition>
        </template>
        <div v-else-if="type !== null" class="flex flex-col h-full items-center justify-between">
            <AppStep7CardSuccess v-if="type === 'success'" class="mb-4"/>
            <AppStep7CardCancel v-else-if="type === 'cancel'" class="mb-4"/>
            <AppStep7CardError v-else-if="type === 'error'" class="mb-4"/>

            <div class="w-full max-w-[368px]">
                <AppUiBtn type="link" text="Отменить запись" class="mb-2"
                          v-if="type === 'success'"
                          @click="cancelApp()"/>
                <AppUiBtn type="white" text="Готово" class="mb-2"
                          v-if="type === 'success' || type === 'cancel'"
                          @click="appStore.closeApp"/>
                <AppUiBtn v-if="appStore.payLink !== null" text="Оплатить сейчас" class="mb-2"/>
            </div>
        </div>
    </div>
</template>

<script>
import {useApp} from "~/store/app/app";

export default {
    data() {
        return {
            type: null,
            loader: true,
            text: "Создаем визит"
        }
    },
    setup() {
        const appStore = useApp();

        return {
            appStore
        }
    },
    methods: {
        async cancelApp() {
            this.loader = true;
            this.text = "Отменяем визит";
            this.type = "cancel";
            await this.appStore.cancelApp();
            await this.appStore.clearApp(7);

            setTimeout(() => {
                this.loader = false;
            }, 1000);
        }
    },
    async mounted() {
        const appStatus = await this.appStore.createApp();
        await this.appStore.createAppMail(appStatus);
        this.type = appStatus ? "success" : "error";

        setTimeout(() => {
            this.loader = false;
        }, 1000);
    }
}
</script>